import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import I18n from "../../i18n.js";
import { loadChatThreads } from '../../ducks/chats';
import DefaultAvatar from '../../images/default_avatar.png';

class PrivateMessages extends React.Component {
  componentDidMount() {
    this.props.loadChatThreads();
  }

  renderThreads() {
    var unreadMessageClass =
      'padding-0-5 border-1 border-bottom border-color-border flex unread-message';
    var messageClass = 'padding-0-5 border-1 border-bottom border-color-border flex';
    return this.props.threads.map(thread => {
      return (
        <Link
          key={thread.id}
          className={thread.has_unread_messages ? unreadMessageClass : messageClass}
          to={`/users/${thread.id}#private`}
        >
          <img
            className="avatar messages__avatar"
            src={thread.avatar_url || DefaultAvatar}
          />
          <span className="self-center">{thread.first_name}</span>
          <span className="self-center margin-auto-left">{I18n.t("js.global.show_messages")}</span>
        </Link>
      );
    });
  }

  render() {
    return (
      <div className="grow margin-top margin-1 overflow-y-auto">
        {this.renderThreads()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    threads: state.chats.threads,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { loadChatThreads }
  )(PrivateMessages)
);
