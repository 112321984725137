import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { HOST } from "../globals";
import I18n from "../i18n.js";

import { loadTag, getTagBySlug } from "../ducks/tags";
import { setPostsFilterTags } from "../ducks/posts";
import { closeFilters } from "../ducks/navigation";
import { getOrganizations } from "../ducks/organizations";

import CalendarEventForm from "./CalendarEventForm";

class EventModal extends React.Component {
  state = {
    showCalendarModal: false,
  };

  componentDidMount() {
    this.props.loadTag({
      id: this.props.match.params.eventId,
      openedFrom: this.props.location.state && this.props.location.state.openedFrom,
    });
  }

  componentDidUpdate() {
    if (this.formattedContent) {
      const calendarLinks = this.formattedContent.querySelectorAll(
        "a[href='http://open-calendar']"
      );
      if (calendarLinks && calendarLinks.forEach) {
        calendarLinks.forEach(el => {
          el.addEventListener("click", event => {
            event.preventDefault();
            this.setState({ showCalendarModal: true });
          });
        });
      }

      const showMessagesLinks = this.formattedContent.querySelectorAll(
        "a[href='http://show-messages']"
      );
      if (showMessagesLinks && showMessagesLinks.forEach) {
        showMessagesLinks.forEach(el => {
          el.addEventListener("click", event => {
            event.preventDefault();
            this.props.setPostsFilterTags([this.props.tag.id]);
            this.props.history.push("/home");
          });
        });
      }
    }
  }

  renderCalendarEventForm() {
    return (
      <div className="calendar-event-form">
        <h1>{I18n.t("js.event_modal.naapuripyora_calendar")}</h1>
        <iframe
          id="naapuripyora-google-calendar"
          src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;mode=WEEK&amp;height=400&amp;wkst=2&amp;bgcolor=%23ffffff&amp;src=kiskolabs.com_fg7lqrs5h2292mo2fgqcufaet4%40group.calendar.google.com&amp;color=%23B1440E&amp;ctz=Europe%2FHelsinki"
          style={{ borderWidth: 0 }}
          width="100%"
          height="400"
          frameBorder="0"
          scrolling="no"
        />
        <h1>{I18n.t("js.event_modal.reserve_bike")}</h1>
        <p>{I18n.t("js.event_modal.reserve_bike_note")}</p>
        <CalendarEventForm />
      </div>
    );
  }

  renderCalendarModal() {
    if (this.state.showCalendarModal) {
      return (
        <div className="text-content modal" style={styles.container}>
          <div className="text-align-right close-x">
            <button
              className="size-2"
              onClick={() => {
                this.setState({ showCalendarModal: false });
              }}
            >
              ×
            </button>
          </div>
          {this.renderCalendarEventForm()}
        </div>
      );
    }
  }

  currentUserMemberOfOrganizationTag() {
    return (
      this.props.tag.organization_id &&
      this.props.organizations.find(
        organization => organization.id === this.props.tag.organization_id
      )
    );
  }

  render() {
    if (!this.props.tag) {
      return null;
    }
    return (
      <React.Fragment>
        {this.renderCalendarModal()}
        <div
          className="text-content modal"
          style={{
            ...styles.container,
            display: this.state.showCalendarModal ? "none" : "block",
          }}
        >
          <div className="text-align-right close-x">
            {this.currentUserMemberOfOrganizationTag() ? (
              <a href={`${HOST}/tags/${this.props.tag.id}/edit`} className="link">
                {I18n.t("js.global.edit")}
              </a>
            ) : null}
            <Link className="size-2" to={"/home"}>
              ×
            </Link>
          </div>

          <h1 className="bold modal-heading">{this.props.tag.name}</h1>

          <div className="formatted_content">
            <div
              dangerouslySetInnerHTML={{ __html: this.props.tag.body }}
              ref={el => (this.formattedContent = el)}
            />
            <div className="text-align-center margin-2 margin-top">
              <Link
                onClick={() => {
                  this.props.setPostsFilterTags([this.props.tag.id]);
                  this.props.closeFilters();
                }}
                className="generic__button"
                to={"/home"}
              >
                {I18n.t("js.global.show_messages")}
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  container: {
    background: "#fff",
    padding: "3rem 2rem 2rem",
    overflowY: "scroll",
    marginLeft: "1rem",
    maxWidth: "700px",
    zIndex: 1,
    position: "relative",
    height: "100%",
  },
};

const mapStateToProps = (state, props) => {
  return {
    tag: getTagBySlug(state, props.match.params.eventId),
    organizations: getOrganizations(state),
  };
};

export default connect(
  mapStateToProps,
  { loadTag, setPostsFilterTags, closeFilters }
)(EventModal);
