import React from 'react';

const MARKER_SIZE = 20;
const style = {
  position: 'absolute',
  background: 'red',
  width: MARKER_SIZE,
  height: MARKER_SIZE,
  left: -MARKER_SIZE / 2,
  top: -MARKER_SIZE / 2,
};

export default class Marker extends React.Component {
  render() {
    return <div style={style} />;
  }
}
