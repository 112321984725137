import React from 'react';
import { connect } from 'react-redux';
import { createMessage } from '../../ducks/chats';
import Isvg from 'react-inlinesvg';

import WriteField from './WriteField';

const mapStateToProps = state => {
  return {
    currentUser: state.users.current_user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createComment: (userId, content) => {
      return dispatch(createMessage(userId, content));
    },
  };
};

const WritePrivateMessage = connect(mapStateToProps, mapDispatchToProps)(WriteField);

export default WritePrivateMessage;
