import React from "react";
import Moment from "react-moment";
import I18n from "../i18n.js";

export default class DateFormat extends React.Component {
  displayFromNow() {
    return (new Date() - new Date(this.props.date)) / 1000 / 60 / 60 / 24 < 1;
  }

  render() {
    let momentFormat = { format: "D. MMMM[ta]" };
    if (this.displayFromNow()) {
      momentFormat = {};
    }

    return (
      <Moment fromNow={this.displayFromNow()} {...momentFormat} locale={I18n.currentLocale()}>
        {this.props.date}
      </Moment>
    );
  }
}
