import React from "react";

import I18n from "../i18n.js";

const SignUpInstruction = () => (
  <div>
    <p className="size-0-75" style={{ maxWidth: "25em", margin: "0 auto" }}>
      {I18n.t("js.sign_up_instructions.sign_up_text")}
    </p>
    <p className="margin-top margin-0-5" style={{ maxWidth: "16em", margin: "0 auto" }}>
      <a className="link homeIcon" href="/users/sign_up">
        {I18n.t("js.sign_up_instructions.register")}
      </a>{" "}
      {I18n.t("js.global.or")}{" "}
      <a className="link homeIcon" href="/users/sign_in">
        {I18n.t("js.sign_up_instructions.sign_in")}
      </a>
    </p>
  </div>
);
export default SignUpInstruction;
