import axios from "axios";
import { normalize, schema } from "normalizr";
import { fitBounds } from "google-map-react/utils";

import { HOST, MIN_ZOOM } from "../globals";

import { LOAD_FULFILLED as LOAD_TAGS } from "./tags";
import { getOrganization } from "./organizations";

export const LOAD = "nappi-naapuri/resources/LOAD";
export const LOAD_BOUNDS = "nappi-naapuri/resources/LOAD_BOUNDS";
export const LOAD_RESOURCE_BOUNDS = "nappi-naapuri/resources/LOAD_RESOURCE_BOUNDS";
export const LOAD_RESOURCE = "nappi-naapuri/resources/LOAD_RESOURCE";
export const LOAD_OWN = "nappi-naapuri/resources/LOAD_OWN";
const CREATE_POST = "nappi-naapuri/resources/CREATE_POST";
const DESTROY_POST = "nappi-naapuri/resources/DESTROY_POST";
const UPDATE_POST = "nappi-naapuri/resources/UPDATE_POST";
const CREATE_COMMENT = "nappi-naapuri/resources/CREATE_COMMENT";
const DESTROY_COMMENT = "nappi-naapuri/resources/DESTROY_COMMENT";
const TOGGLE_RESOURCES_FILTER_TAG = "nappi-naapuri/resources/TOGGLE_RESOURCES_FILTER_TAG";
const SET_RESOURCES_FILTER_TAGS = "nappi-naapuri/resources/SET_RESOURCES_FILTER_TAGS";
const REMOVE_TAG_FILTERS = "nappi-naapuri/resources/REMOVE_TAG_FILTERS";
const SET_MAP_CENTER = "nappi-naapuri/resources/SET_MAP_CENTER";

const MINIMUM_ZOOM = MIN_ZOOM;

const author = new schema.Entity("authors");

const comment = new schema.Entity("comments", {
  author: author,
});

const resource = new schema.Entity("resources", {
  author: author,
  comments: [comment],
});

export default function reducer(
  state = {
    resources: {},
    resourceIds: [],
    comments: {},
    ownResources: [],
    commentsLoaded: [],
    loading: false,
    creating: false,
    filterTagIds: [],
  },
  action
) {
  let normalized, coordinates, sw, ne;
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD_RESOURCE_BOUNDS}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD_RESOURCE_BOUNDS}_FULFILLED`: {
      let { center, zoom } = fitBounds(action.payload.bounds, action.meta.mapSize);
      if (zoom < MINIMUM_ZOOM) {
        zoom = MINIMUM_ZOOM;
      }

      return {
        ...state,
        loading: false,
        center: state.center || center,
        zoom,
        sw: action.payload.bounds.sw,
        ne: action.payload.bounds.ne,
      };
    }
    case `${LOAD}_FULFILLED`: {
      throw "no";
      normalized = normalize(action.payload.resources, [resource]);
      const combinedResources = { ...state.resources, ...normalized.entities.resources };

      coordinates = Object.values(combinedResources).map(resource => resource.address);
      if (coordinates.length > 0) {
        sw = {
          lat: Math.min(...coordinates.map(c => parseFloat(c.latitude))),
          lng: Math.min(...coordinates.map(c => parseFloat(c.longitude))),
        };
        ne = {
          lat: Math.max(...coordinates.map(c => parseFloat(c.latitude))),
          lng: Math.max(...coordinates.map(c => parseFloat(c.longitude))),
        };
      }

      return {
        ...state,
        loading: false,
        resourceIds: Array.from(new Set(state.resourceIds.concat(normalized.result))),
        center: action.payload.center,
        sw,
        ne,
        zoom,
        resources: combinedResources,
      };
    }
    case `${LOAD_BOUNDS}_PENDING`:
      return { ...state, zoom: action.meta.zoom, loading: true };
    case `${LOAD_BOUNDS}_FULFILLED`:
      normalized = normalize(action.payload.resources, [resource]);

      console.log("old", state.zoom, "new", action.meta.zoom);
      if (state.zoom === action.meta.zoom) {
        return {
          ...state,
          loading: false,
          resourceIds: normalized.result,
          center: action.meta.center,
          zoom: action.meta.zoom,
          sw: action.meta.bounds.sw,
          ne: action.meta.bounds.ne,
          resources: { ...state.resources, ...normalized.entities.resources },
        };
      } else {
        return {
          ...state,
          resourceIds: normalized.result,
          resources: { ...state.resources, ...normalized.entities.resources },
        };
      }
    case `${LOAD_BOUNDS}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_OWN}_FULFILLED`:
      return {
        ...state,
        loading: false,
        ownResources: action.payload.resources,
      };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_RESOURCE}_FULFILLED`: {
      const normalizedResource = normalize(action.payload.resource, resource);
      const normalizedComments = normalize(action.payload.comments, [comment]);

      return {
        ...state,
        resources: { ...state.resources, ...normalizedResource.entities.resources },
        commentsLoaded: state.commentsLoaded.concat(normalizedResource.result),
        comments: { ...state.comments, ...normalizedComments.entities.comments },
      };
    }
    case `${CREATE_POST}_PENDING`:
      return { ...state, creating: true };
    case `${CREATE_POST}_FULFILLED`:
      return {
        ...state,
        creating: false,
        resourceIds: [action.payload.id, ...state.resourceIds],
        resources: { ...state.resources, [action.payload.id]: action.payload },
      };
    case `${CREATE_POST}_REJECTED`:
      return { ...state, creating: false };
    case `${UPDATE_POST}_FULFILLED`:
      return {
        ...state,
        // creating: false,
        resources: { ...state.resources, [action.payload.id]: action.payload },
      };
    case `${DESTROY_POST}_FULFILLED`:
      return {
        ...state,
        resourceIds: state.resourceIds.filter(id => id !== action.payload.id),
      };
    case `${CREATE_COMMENT}_PENDING`:
      return { ...state, creating: true };
    case `${CREATE_COMMENT}_FULFILLED`:
      return {
        ...state,
        creating: false,
        comments: { ...state.comments, [action.payload.id]: action.payload },
      };
    case `${DESTROY_COMMENT}_FULFILLED`:
      let newComments = { ...state.comments };
      delete newComments[action.payload.id];
      return {
        ...state,
        comments: newComments,
      };
    case `${CREATE_COMMENT}_REJECTED`:
      return { ...state, creating: false };
    case TOGGLE_RESOURCES_FILTER_TAG: {
      const updatedTags = updateFilterTags(state.filterTagIds, action.payload.tagId);
      return {
        ...state,
        filterTagIds: updatedTags.length === 0 ? [] : updatedTags,
      };
    }
    case SET_RESOURCES_FILTER_TAGS:
      return { ...state, filterTagIds: action.payload.map(tagId => parseInt(tagId, 10)) };
    case REMOVE_TAG_FILTERS:
      return { ...state, filterTagIds: action.payload.tagIds };
    case LOAD_TAGS:
      return {
        ...state,
        filterTagIds: state.filterTagIds || action.payload.map(tag => tag.id),
      };
    case SET_MAP_CENTER:
      return { ...state, center: action.payload };
    default:
      return state;
  }
}

const updateFilterTags = (currentTagIds, toggledId) => {
  currentTagIds = currentTagIds || [];
  if (currentTagIds.includes(toggledId)) {
    return currentTagIds.filter(id => id !== toggledId);
  } else {
    return currentTagIds.concat(toggledId);
  }
};

export const setResourcesFilterTags = tagIds => {
  return {
    type: SET_RESOURCES_FILTER_TAGS,
    payload: tagIds,
  };
};

export const loadOwnResources = () => {
  return {
    type: LOAD_OWN,
    payload: axios.get(`${HOST}/api/v1/resources/own`).then(response => response.data),
  };
};

const getOrigin = state => {
  const currentUser = state.users.current_user;
  let activeAddress;
  if (currentUser) {
    activeAddress = currentUser.active_address;
  } else {
    // TODO
    activeAddress = { latitude: 60.186191, longitude: 24.9659024 };
  }

  return {
    lat: parseFloat(activeAddress.latitude),
    lng: parseFloat(activeAddress.longitude),
  };
};

export const loadInitialBounds = mapSize => {
  return (dispatch, getState) => {
    const center = getOrigin(getState());

    return dispatch({
      type: LOAD_RESOURCE_BOUNDS,
      meta: { mapSize },
      payload: axios
        .get(`${HOST}/api/v1/resource_bounds?lat=${center.lat}&lng=${center.lng}`)
        .then(response => response.data),
    });
  };
};

export const loadResourceBounds = ({ mapSize, center, forceOrigin }) => {
  return (dispatch, getState) => {
    const { resources } = getState();
    const offset = resources.resourceIds.length;
    const tagIdsFragment = resources.filterTagIds.map(id => `tag_ids[]=${id}`).join("&");
    center = center || getOrigin(getState());

    let query = `lat=${center.lat}&lng=${center.lng}&offset=${offset}&${tagIdsFragment}`;

    if (forceOrigin) {
      query += "&force_origin=true";
    }

    return dispatch({
      type: LOAD_RESOURCE_BOUNDS,
      meta: { mapSize },
      payload: axios
        .get(`${HOST}/api/v1/resource_bounds?${query}`)
        .then(response => response.data),
    });
  };
};

export const loadResources = options => {
  const offset = (options && options.offset) || 0;
  return {
    type: LOAD,
    payload: axios
      .get(`${HOST}/api/v1/resources?offset=${offset}`)
      .then(response => response.data),
  };
};

export const loadBounds = ({ center, bounds, zoom }) => {
  return (dispatch, getState) => {
    const origin = getOrigin(getState());

    return dispatch({
      type: LOAD_BOUNDS,
      meta: {
        center,
        bounds,
        zoom,
      },
      payload: axios
        .get(
          `${HOST}/api/v1/resources?lat=${origin.lat}&lng=${origin.lng}&sw=${bounds.sw
            .lat +
            "," +
            bounds.sw.lng}&ne=${bounds.ne.lat + "," + bounds.ne.lng}`
        )
        .then(response => response.data),
    });
  };
};

export const loadResource = resourceId => {
  return {
    type: LOAD_RESOURCE,
    payload: axios
      .get(`${HOST}/api/v1/resources/${resourceId}`)
      .then(response => response.data),
  };
};

export function toggleResourcesFilterTag(tagId) {
  return (dispatch, getState) => {
    const { resourceTags } = getState();

    dispatch({
      type: TOGGLE_RESOURCES_FILTER_TAG,
      meta: {
        allTagIds: resourceTags.tags.map(tag => tag.id),
      },
      payload: { tagId },
    });
  };
}

export function removeTagFilters() {
  return (dispatch, getState) => {
    const { tags } = getState();

    dispatch({
      type: REMOVE_TAG_FILTERS,
      payload: { tagIds: tags.tags.map(tag => tag.id) },
    });
  };
}

export function createResource({
  tag_id,
  content,
  active_days,
  address_id,
  organization_id,
  image_attributes,
}) {
  return {
    type: CREATE_POST,
    payload: axios
      .resource(`${HOST}/api/v1/resources`, {
        resource: {
          tag_id,
          content,
          active_days,
          address_id,
          organization_id,
          image_attributes,
        },
      })
      .then(response => response.data),
  };
}

export function updateResource(
  resourceId,
  { tag_id, content, active_days, image_attributes }
) {
  return {
    type: UPDATE_POST,
    payload: axios
      .patch(`${HOST}/api/v1/resources/${resourceId}`, {
        resource: { tag_id, content, active_days, image_attributes },
      })
      .then(response => response.data),
  };
}

export function createComment(resource_id, { content, image }) {
  return {
    type: CREATE_COMMENT,
    payload: axios
      .post(`${HOST}/api/v1/resource_comments`, {
        comment: {
          resource_id,
          content,
          image,
        },
      })
      .then(response => response.data),
  };
}

export function destroyResource(resourceId) {
  return {
    type: DESTROY_POST,
    payload: axios
      .delete(`${HOST}/api/v1/resources/${resourceId}`)
      .then(response => response.data),
  };
}

export function destroyComment(commentId) {
  return {
    type: DESTROY_COMMENT,
    payload: axios
      .delete(`${HOST}/api/v1/resource_comments/${commentId}`)
      .then(response => response.data),
  };
}

export function setMapCenter(center) {
  return {
    type: SET_MAP_CENTER,
    payload: center,
  };
}

export function setMapBottom(point) {
  return (dispatch, getState) => {
    const {
      resources: { ne, sw },
    } = getState();

    let nudgeTowardsBottomOfView;
    if (sw && ne) {
      nudgeTowardsBottomOfView = (sw.lat - ne.lat) * 0.4;
    } else {
      nudgeTowardsBottomOfView = -0.007;
    }
    const center = { lat: point.lat - nudgeTowardsBottomOfView, lng: point.lng };

    dispatch({
      type: SET_MAP_CENTER,
      payload: center,
    });
  };
}

export function getResources(state) {
  if (state.users.users.length === 0) return [];
  let resources = state.resources.resourceIds.map(id => state.resources.resources[id]);
  if (state.resources.filterTagIds && state.resources.filterTagIds.length > 0) {
    resources = resources.filter(resource => {
        const found = resource.tags.some(tag => state.resources.filterTagIds.includes(tag.id))
        return found
      }
    );
  }

  return resources.map(resource => {
    let commentsCount;
    if (state.resources.commentsLoaded.includes(resource.id)) {
      commentsCount = getCommentsForResource(state, resource.id).length;
    } else {
      commentsCount = resource.comments_count;
    }
    return {
      ...resource,
      tag: state.resourceTags.tags.find(tag => tag.id === resource.tag_id),
      organization:
        resource &&
        resource.organization_id &&
        getOrganization(state, resource.organization_id),
      user: state.users.users[resource.author_id],
      comments_count: commentsCount,
    };
  });
}

export function getResource(state, resourceId) {
  let resource = state.resources.resources[resourceId];
  let commentsCount;
  if (state.resources.commentsLoaded.includes(resourceId)) {
    commentsCount = getCommentsForResource(state, resourceId).length;
  } else {
    commentsCount = resource && resource.comments_count;
  }
  // loadCurrentUser().then(user => {
  //   console.log(user);
  // });

  return resource
    ? {
        ...resource,
        tag: state.tags.tags.find(tag => tag.id === resource.tag_id),
        organization:
          resource &&
          resource.organization_id &&
          getOrganization(state, resource.organization_id),
        user: state.users.users[resource.author_id],
        comments_count: commentsCount,
        active_days: 1,
      }
    : null;
}

export function getCommentsForResource(state, resourceId) {
  return Object.values(state.resources.comments)
    .filter(comment => comment.resource_id == resourceId)
    .map(comment => {
      return {
        ...comment,
        user: state.users.users[comment.author_id],
      };
    });
}

export function getBounds(state) {
  return { sw: state.resources.sw, ne: state.resources.ne };
}

export function getCenter(state) {
  return state.resources.center;
}

export function getZoom(state) {
  return state.resources.zoom;
}

export function getIsMinimumZoom(state) {
  if (!getZoom(state)) {
    return true;
  }
  return getZoom(state) <= MINIMUM_ZOOM;
}

export function getOwnResources(state) {
  return state.resources.ownResources;
}

export function isCommentDeletable(user, comment) {
  if (!user || !comment) {
    return false;
  }
  // TODO: Organization support?
  return user.id === comment.author_id;
}

export function isResourceDeletable(user, resource) {
  if (!user || !resource) {
    return false;
  }
  return (
    user.id === resource.user.id ||
    (resource.organization_id && user.organization_id === resource.organization_id)
  );
}
