import React from "react";
import Textarea from "react-textarea-autosize";
import classNames from "classnames/bind";
import Isvg from "react-inlinesvg";
import DefaultAvatar from "../../images/default_avatar.png";

import StatusBar from "@uppy/react/lib/StatusBar";
import { createUppy, fileToJson } from "../../helpers/uploads";
import { statusBarTranslations } from "../../helpers/translations";

import IconSend from "../../images/icon-send.svg";
import PostFileUpload from "./PostFileUpload";
import Image from "./Image";

export default class WriteField extends React.Component {
  state = {
    comment: "",
    uploading: false,
  };

  constructor(props) {
    super(props);

    this.uppy = createUppy("/comment_images/presign");
    this.uppy.on("upload", _data => {
      this.setState({
        uploading: true,
      });
    });
    this.uppy.on("complete", _data => {
      this.setState({
        uploading: false,
      });
    });
  }

  handleImagesUploaded = uploads => {
    const images = uploads.map(file => ({
      preview: URL.createObjectURL(file.data),
      file: file,
      id: file.id,
    }));

    this.setState(state => ({ image: images[0] }));
  };

  handleImageRemove = index => {
    if (this.state.image && this.state.image.id) {
      this.uppy.removeFile(this.state.image.id);
    }
    this.setState({ image: null });
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.comment.trim().length > 0) {
      this.props.createComment(this.props.commentableId, {
        content: this.state.comment,
        image: this.state.image && JSON.stringify(fileToJson(this.state.image.file)),
      });

      if (this.state.image && this.state.image.id) {
        this.uppy.removeFile(this.state.image.id);
      }
      this.setState({
        comment: "",
        image: null,
      });
    }
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }


  render() {
    if (!this.props.currentUser) return null;

    const writeClassNames = classNames("write-comment", {
      "write-comment--active": this.state.comment.length > 0 && !this.state.uploading,
    });
    const submitClassNames = classNames("write-comment__submit", {
      "write-comment__submit--active":
        this.state.comment.length > 0 && !this.state.uploading,
    });

    return (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)} className={writeClassNames}>
          <div
            style={{
              backgroundImage: `url(${(this.props.currentUser &&
                this.props.currentUser.avatar_url) ||
                DefaultAvatar}`,
            }}
            className="write-textarea__avatar avatar"
          />
          <Textarea
            className="write-comment__textarea"
            type="textarea"
            placeholder={this.props.placeholder}
            minRows={1}
            value={this.state.comment}
            onChange={this.handleChange.bind(this)}
          />
          <PostFileUpload
            uppy={this.uppy}
            onImagesUploaded={this.handleImagesUploaded}
            visible={true}
          />
          <button
            type="submit"
            className={submitClassNames}
            disabled={this.state.uploading || this.state.comment.length === 0}
          >
            <Isvg src={IconSend} className="write-comment__submit-icon" />
          </button>
        </form>
        <StatusBar
          uppy={this.uppy}
          hideUploadButton
          hideAfterFinish
          showProgressDetails
          locale={statusBarTranslations}
        />
        <Image image={this.state.image} onImageRemove={this.handleImageRemove} />
      </div>
    );
  }
}
