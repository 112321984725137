import axios from 'axios';
import { HOST } from '../globals';

import findIndex from "lodash.findindex";

export const LOAD = 'nappi-naapuri/chats/LOAD';
const LOAD_THREADS = 'nappi-naapuri/chats/LOAD_THREADS';
const CREATE_MESSAGE = 'nappi-naapuri/chats/CREATE_MESSAGE';
const MARK_THREAD_READ = 'nappi-naapuri/chats/MARK_THREAD_READ';

export default function reducer(state = { messages: [], threads: [], loading: false, creating: false }, action) {
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD}_FULFILLED`:
      return { ...state, loading: false, messages: action.payload };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_THREADS}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD_THREADS}_FULFILLED`:
      return { ...state, loading: false, threads: action.payload };
    case `${LOAD_THREADS}_REJECTED`:
      return { ...state, loading: false };
    case `${CREATE_MESSAGE}_PENDING`:
      return { ...state, creating: true };
    case `${CREATE_MESSAGE}_FULFILLED`:
      return { ...state, creating: false, messages: [...state.messages, action.payload] };
    case `${CREATE_MESSAGE}_REJECTED`:
      return { ...state, creating: false };
    case MARK_THREAD_READ:
      const index = findIndex(state.threads, (thread) => thread.id === action.payload);

      if (index) {
        const updatedThreads = [
          ...state.threads.slice(0, index),
          { ...state.threads[index], has_unread_messages: false },
          ...state.threads.slice(index + 1)
        ]

        return { ...state, threads: updatedThreads };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export const loadChatThreads = () => {
  return {
    type: LOAD_THREADS,
    payload: axios.get(`${HOST}/api/v1/chats`).then(response => response.data),
  };
};

export const loadChat = userId => {
  return {
    type: LOAD,
    payload: axios.get(`${HOST}/api/v1/chats/${userId}`).then(response => response.data),
  };
};

export function createMessage(receiver_id, attributes) {
  return {
    type: CREATE_MESSAGE,
    payload: axios
      .post(`${HOST}/api/v1/private_messages`, { private_message: { receiver_id, ...attributes } })
      .then(response => response.data),
  };
}

export const markThreadRead = (senderId) => {
  return {
    type: MARK_THREAD_READ,
    payload: senderId,
  };
};


export function getUnreadThreadsCount(state, userId) {
  if (state.chats.threads && state.chats.threads.length > 0) {
    return state.chats.threads.filter(
      (thread) => thread.has_unread_messages
    ).length;
  } else {
    return null;
  }
}

export function getMessages(state, userId) {
  if (!state.users.current_user) return [];
  return state.chats.messages.map(message => {
    if (
      [state.users.current_user.id, userId].includes(message.sender_id) &&
      [state.users.current_user.id, userId].includes(message.receiver_id)
    )
      return {
        ...message,
        user: state.users.users[message.sender_id],
      };
  }).filter(message => message);
}
