import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import { connect } from "react-redux";
import Isvg from "react-inlinesvg";

import I18n from "../../i18n.js";

import DateFormat from "../DateFormat";
import ImageViewer from "./ImageViewer";
import IconTrash from "../../images/trash-2.svg";

import { destroyComment as destroyPostComment } from "../../ducks/posts.js";
import { destroyComment as destroyResourceComment } from "../../ducks/resources.js";

class Comment extends React.Component {
  state = {
    hoveringAvatar: false,
  };

  destroyPostComment = () => {
    if (window.confirm(I18n.t("js.feed.comment.deletion_are_you_sure"))) {
      this.props.destroyPostComment(this.props.data.id);
    }
  };

  destroyResourceComment = () => {
    if (window.confirm(I18n.t("js.feed.comment.deletion_are_you_sure"))) {
      this.props.destroyResourceComment(this.props.data.id);
    }
  };

  renderDeleteButton() {
    if (this.props.data.deletable && !!this.props.data.post_id ) {
      return (
        <div className="margin-0-5 margin-left flex align-center">
          <button onClick={this.destroyPostComment}>
            <Isvg className="icon-post-option" src={IconTrash} />
          </button>
        </div>
      );
    }
    if (this.props.data.deletable && !!this.props.data.resource_id ) {
      return (
        <div className="margin-0-5 margin-left flex align-center">
          <button onClick={this.destroyResourceComment}>
            <Isvg className="icon-post-option" src={IconTrash} />
          </button>
        </div>
      );
    }
  }

  render() {
    const postUserClassNames = classNames("post__user", {
      "post__user--hover": this.state.hoveringAvatar,
    });
    return (
      <div className="post post--comment">
        <Link
          to={`/users/${this.props.data.user.id}`}
          onMouseOver={() => {
            this.setState({ hoveringAvatar: true });
          }}
          onMouseOut={() => {
            this.setState({ hoveringAvatar: false });
          }}
          className="post__avatar"
        >
          <img src={this.props.data.user.avatar_url} className="avatar" />
        </Link>

        <div className="post__content">
          <div className="post__summary">
            <Link to={`/users/${this.props.data.user.id}`} className={postUserClassNames}>
              {this.props.data.user.first_name}
            </Link>{" "}
            <ReactMarkdown className="message_formatted_content" source={this.props.data.content} plugins={[breaks]} />
          </div>
          <div className="post__date">
            <DateFormat date={this.props.data.created_at} />
          </div>
          <ImageViewer image={this.props.data.image} />
        </div>

        <div className="flex align-right">{this.renderDeleteButton()}</div>
      </div>
    );
  }
}

export default connect(
  null,
  { destroyPostComment, destroyResourceComment }
)(Comment);
