import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import I18n from "../i18n.js";
import {
  getResource,
  loadResource,
  getCommentsForResource,
  isResourceDeletable,
  isCommentDeletable,
} from "../ducks/resources";
import WriteResourceComment from "./feed/WriteResourceComment";
import Resource from "./feed/Resource";
import Comment from "./feed/Comment";
import SignUpInstruction from "./SignUpInstruction";

import { facebookShareUrl } from "../helpers/facebook";

class ResourceThread extends React.Component {
  componentDidMount() {
    this.props.onMount && this.props.onMount();
    this.props.loadResource(this.props.match.params.resourceId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.onMount && this.props.onMount();
    }
  }

  renderComments() {
    return this.props.comments.map(comment => {
      return <Comment key={comment.id} data={comment} />;
    });
  }

  renderResource() {
    return (
      <div>
        <div className="grow flex vertical overflow-y-auto">
          <Resource deletable={this.props.deletable} linked={false} data={this.props.resource} />
          <div className="comment-wrapper grow margin-bottom margin-5">
            {this.renderComments()}
          </div>
        </div>
        <div className="comment-wrapper__write">
          <WriteResourceComment
            commentableId={this.props.resource.id}
            placeholder={I18n.t("js.thread.write_comment_or_recomenmendation")}
          />
        </div>
      </div>
    );
  }

  render404() {
    return (
      <div className="grow flex vertical overflow-y-auto margin-y margin-3">
        <h1 className="size-1-5 museo-sans weight-700 margin-bottom margin-1 text-align-center">
          {I18n.t("js.thread.message_not_found_title")}
        </h1>
        <p className="size-1 text-align-center">
          {I18n.t("js.thread.message_not_found_text")}
        </p>
        <div className="text-align-center margin-top margin-1">
          <a href="/home" className="link">
            {I18n.t("js.thread.to_frontpage")}
          </a>
        </div>
      </div>
    );
  }

  // TODO facebook share not tested
  render() {
    const facebookAppId = document.body.getAttribute("data-facebook-app-id");
    if (!this.props.currentUser) {
      return (
        <div>
          {this.props.resource ? this.renderResource() : null}
          <div className="padding-1 padding-top border-top-2 border-color-primary margin-top margin-1">
            <SignUpInstruction />
          </div>
        </div>
      );
    } else {
      return (
        <div className="grow flex vertical">
          <div className="padding-0-75 flex justify align-center">
            {this.props.resource && (
              <React.Fragment>
                <Link className="link" to={"/resources"} style={{ float: "left" }}>
                  {I18n.t("js.global.back")}
                </Link>
                <a
                  style={{ float: "right" }}
                  href={facebookShareUrl(
                    facebookAppId,
                    window.location,
                    "https://www.nappinaapuri.fi"
                  )}
                >
                  <div id="fb-share-button">
                    <svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet">
                      <path
                        className="svg-icon-path"
                        d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z"
                      />
                    </svg>
                    <span>{I18n.t("js.global.share_to_facebook")}</span>
                  </div>
                </a>
              </React.Fragment>
            )}
          </div>
          {this.props.resource ? this.renderResource() : this.render404()}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  const resource = getResource(state, parseInt(props.match.params.resourceId));
  return {
    currentUser: state.users.current_user,
    resource,
    comments: getCommentsForResource(state, parseInt(props.match.params.resourceId)).map(
      comment => ({
        ...comment,
        deletable: isCommentDeletable(state.users.current_user, comment),
      })
    ),
    deletable: isResourceDeletable(state.users.current_user, resource),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { loadResource }
  )(ResourceThread)
);
