import { MIN_ZOOM } from "../globals";

const mapOptions = {
  minZoom: MIN_ZOOM,
  fullscreenControl: false,
  disableDoubleClickZoom: true,
  styles: [
    {
      featureType: 'landscape',
      stylers: [{ color: '#8c9286' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      stylers: [{ color: '#7c8079' }],
    },
    {
      featureType: 'water',
      stylers: [{ color: '#afbfbe' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#b9b4ab' }, { visibility: 'simplified' }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#b9b3ab' }, { visibility: 'simplified' }],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export default mapOptions;
