import React from "react";
import { connect } from "react-redux";
import { createComment } from "../../ducks/resources";

import WriteField from "./WriteField";

// TODO merge/refactor with WriteComment
const mapStateToProps = state => {
  return {
    currentUser: state.users.current_user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createComment: (resource_id, attributes) => {
      return dispatch(createComment(resource_id, attributes));
    },
  };
};

const WriteResourceComment = connect(
  mapStateToProps,
  mapDispatchToProps
)(WriteField);

export default WriteResourceComment;
