import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { loadOwnPosts } from '../../ducks/posts';
import Post from './Post';

class PublicMessages extends React.Component {
  componentDidMount() {
    this.props.loadOwnPosts();
  }

  renderPosts() {
    return this.props.posts.map(post => {
      return <Post key={post.id} data={post} linked displayCommentCount />;
    });
  }

  render() {
    return (
      <div className="grow overflow-y-auto margin-1 margin-top flex vertical">
        {this.renderPosts()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    posts: state.posts.ownPosts,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { loadOwnPosts }
  )(PublicMessages)
);
