import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import I18n from "../i18n.js";

import Resource from "./feed/Resource";
import ResourceFilter from "./ResourceFilter";
import Nav from "./Nav";
import SignUpInstruction from "./SignUpInstruction";
import Logo from "../images/logo.png";
import { getLoading } from "../ducks";
import { toggleMenuOpen } from "../ducks/navigation";
import {
  getResources,
  toggleResourcesFilterTag,
  setResourcesFilterTags,
  removeTagFilters,
  setMapCenter,
  getIsMinimumZoom,
  isResourceDeletable,
} from "../ducks/resources";
import { getTags, getTagBySlug } from "../ducks/resourceTags";
import homeIcon from "../images/home-icon-primary.png";

class Resources extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      resources: []
    }
  }

  componentDidMount() {
    if (this.highlightedItem) {
      this.highlightedItem.scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillReceiveProps(nextProps) {

    // sorts by date
    let sortedByDateResources = nextProps.resources.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at);
    });

    //if map found new items, destructure them after the old items
    if(nextProps.resources.length > this.state.resources.length){

      const oldResourcesIds = this.state.resources.map(item => item.id)
      const newItemsFoundAfterMovingMap = nextProps.resources.filter(nextPropItem => !oldResourcesIds.includes(nextPropItem.id))

      this.setState({resources: [...this.state.resources, ...newItemsFoundAfterMovingMap]})
    }
    //else map found less items than previous zoom/position, order found items by date
    else {
      if(nextProps.resources){
        this.setState({resources: sortedByDateResources })
      }
    }

    if (nextProps.eventId && this.props.eventId != nextProps.eventId) {
      this.props.setResourcesFilterTags([nextProps.eventId]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.highlightResourceId !== this.props.highlightResourceId &&
      this.highlightedItem
    ) {
      this.highlightedItem.scrollIntoView({ behavior: "smooth" });
    }
  }

  handleScroll = event => {
    // const bottom =
    //   event.target.scrollHeight - event.target.scrollTop - 50 <=
    //   event.target.clientHeight;
    // if (bottom) {
    //   console.log('Load more');
    //
    //   if (!this.props.loadingResources) {
    //     this.props.loadMoreResources();
    //   }
    // }
  };


  renderResources() {

    const highlightResourceId = this.props.highlightResourceId;
    if (this.state.resources.length > 0) {

      return this.state.resources.map(resource => {
        const highlightClass = highlightResourceId === resource.id ? "highlight" : "";
        return (
          <div
            key={resource.id}
            ref={node =>
              highlightResourceId === resource.id ? (this.highlightedItem = node) : null
            }
            className={highlightClass + " feed-posts__item"}
          >
            <Resource
              deletable={isResourceDeletable(this.props.currentUser, resource)}
              data={resource}
              linked={true}
              displayCommentCount={true}
            />
          </div>
        );
      });
    } else {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          {I18n.t("js.resources.move_map_to_find_resources")}
        </div>
      );
    }
  }

  toggleResourceTag = tagId => {
    this.props.toggleResourcesFilterTag(tagId);
  };

  renderLogo() {
    if (this.props.currentUser) {
      return <img className="inline logo" src={Logo} />;
    } else {
      return (
        this.props.event && (
          <div>
            <div
              className="avatar eventAvatar"
              style={{ backgroundImage: `url(${this.props.event.logo_url})` }}
            />
            <h1 className="size-1-25 museo-sans bold color-primary margin-top margin-1">
              {this.props.event.name}
            </h1>
            <div className="padding-1 padding-top border-top-2 border-color-primary margin-top margin-1">
              <p>
                <img
                  className="inline logo margin-0-5 margin-top"
                  src={Logo}
                  width="75"
                />
              </p>
              <SignUpInstruction />
            </div>
          </div>
        )
      );
    }
  }

  centerToHome = () => {
    this.props.setMapCenter({
      lat: this.props.currentUser.active_address.latitude,
      lng: this.props.currentUser.active_address.longitude,
    });
  };

  renderLoadMoreButton = () => {
    if (!this.props.currentUser) return;
    if (this.props.loadingResources) {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          <span>{I18n.t("js.global.loading")}</span>
        </div>
      );
    }

    if (this.props.hasMoreResourcesToLoad) {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          <button className="generic__button" onClick={() => this.props.loadMoreResources()}>
            {I18n.t("js.feed.load_more_messages")}
          </button>
        </div>
      );
    } else {
      return <div className="text-align-center margin-1 margin-bottom" />;
    }
  };

  render() {
    return (
      <div style={{ overflow: "auto" }}>
        <div>
          <div className="text-align-center padding-y flex align-center justify relative">
            <div className="headerLeft">
              {this.props.currentUser && (
                <div
                  className="homeIcon inline cursor-pointer"
                  onClick={this.centerToHome}
                >
                  <img src={homeIcon} />
                  <span className="link">{I18n.t("js.feed.home")}</span>
                </div>
              )}
            </div>
            <div className="headerCenter">{this.renderLogo()}</div>
            <div className="headerRight">
              {!this.props.currentUser && (
                <div className="infoLink">
                  <Link className="link" to={"/info"}>
                    {I18n.t("js.feed.info")}
                  </Link>
                </div>
              )}
              {this.props.currentUser && (
                <button
                  className={
                    this.props.menuOpen ? "hamburger hamburgerOpen" : "hamburger"
                  }
                  onClick={this.props.toggleMenuOpen}
                >
                  <div />
                  <div />
                  <div />
                </button>
              )}
            </div>
          </div>
          {this.props.currentUser && (
            <React.Fragment>
              <Nav
                filterTagIds={this.props.filterTagIds}
                tags={this.props.tags}
                organizations={this.props.organizations}
                toggleTag={this.toggleTag}
                removeTagFilters={this.props.removeTagFilters}
                currentUser={this.props.currentUser}
              />
              <div className="margin-bottom margin-1 write-wrapper padding-1 padding-top">
                <ResourceFilter
                  filterTagIds={this.props.filterTagIds}
                  tags={this.props.tags}
                  organizations={this.props.organizations}
                  toggleResourceTag={this.toggleResourceTag}
                  removeTagFilters={this.props.removeTagFilters}
                  currentUser={this.props.currentUser}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="feed-posts" onScroll={this.handleScroll}>
          {this.renderResources()}
          {this.renderLoadMoreButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const tag = state.tags.activeTag;
  return {
    resources: getResources(state),
    tags: getTags(state),
    filterTagIds: state.resources.filterTagIds,
    highlightResourceId: parseInt(props.match.params.resourceId),
    event: tag,
    eventId: tag && tag.id,
    currentUser: state.users.current_user,
    loadingResources: getLoading(state, "resources"),
    hasMoreResourcesToLoad: !getIsMinimumZoom(state),
    menuOpen: state.navigation.menuOpen,
  };
};

export default connect(
  mapStateToProps,
  {
    toggleMenuOpen,
    toggleResourcesFilterTag,
    setResourcesFilterTags,
    removeTagFilters,
    setMapCenter,
  }
)(Resources);
