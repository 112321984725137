import axios from "axios";
import { normalize, schema } from "normalizr";
import { fitBounds } from "google-map-react/utils";

import { HOST, MIN_ZOOM } from "../globals";

import { LOAD_FULFILLED as LOAD_TAGS } from "./tags";
import { getOrganization } from "./organizations";

export const LOAD = "nappi-naapuri/posts/LOAD";
export const LOAD_BOUNDS = "nappi-naapuri/posts/LOAD_BOUNDS";
export const LOAD_POST_BOUNDS = "nappi-naapuri/posts/LOAD_POST_BOUNDS";
export const LOAD_POST = "nappi-naapuri/posts/LOAD_POST";
export const LOAD_OWN = "nappi-naapuri/posts/LOAD_OWN";
const CREATE_POST = "nappi-naapuri/posts/CREATE_POST";
const DESTROY_POST = "nappi-naapuri/posts/DESTROY_POST";
const UPDATE_POST = "nappi-naapuri/posts/UPDATE_POST";
const CREATE_COMMENT = "nappi-naapuri/posts/CREATE_COMMENT";
const DESTROY_COMMENT = "nappi-naapuri/posts/DESTROY_COMMENT";
const TOGGLE_POSTS_FILTER_TAG = "nappi-naapuri/posts/TOGGLE_POSTS_FILTER_TAG";
const SET_POSTS_FILTER_TAGS = "nappi-naapuri/posts/SET_POSTS_FILTER_TAGS";
const REMOVE_TAG_FILTERS = "nappi-naapuri/posts/REMOVE_TAG_FILTERS";
const SET_MAP_CENTER = "nappi-naapuri/posts/SET_MAP_CENTER";
const SET_MAP_BOTTOM = "nappi-naapuri/posts/SET_MAP_BOTTOM";

const MINIMUM_ZOOM = MIN_ZOOM;

const author = new schema.Entity("authors");

const comment = new schema.Entity("comments", {
  author: author,
});

const post = new schema.Entity("posts", {
  author: author,
  comments: [comment],
});

export default function reducer(
  state = {
    posts: {},
    postIds: [],
    comments: {},
    ownPosts: [],
    commentsLoaded: [],
    loading: false,
    creating: false,
    filterTagIds: [],
  },
  action
) {
  let normalized, coordinates, sw, ne;
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD_POST_BOUNDS}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD_POST_BOUNDS}_FULFILLED`: {
      let { center, zoom } = fitBounds(action.payload.bounds, action.meta.mapSize);
      if (zoom < MINIMUM_ZOOM) {
        zoom = MINIMUM_ZOOM;
      }

      return {
        ...state,
        loading: false,
        center: state.center || center,
        zoom,
        sw: action.payload.bounds.sw,
        ne: action.payload.bounds.ne,
      };
    }
    case `${LOAD}_FULFILLED`: {
      throw "no";
      normalized = normalize(action.payload.posts, [post]);
      const combinedPosts = { ...state.posts, ...normalized.entities.posts };

      coordinates = Object.values(combinedPosts).map(post => post.address);
      if (coordinates.length > 0) {
        sw = {
          lat: Math.min(...coordinates.map(c => parseFloat(c.latitude))),
          lng: Math.min(...coordinates.map(c => parseFloat(c.longitude))),
        };
        ne = {
          lat: Math.max(...coordinates.map(c => parseFloat(c.latitude))),
          lng: Math.max(...coordinates.map(c => parseFloat(c.longitude))),
        };
      }

      return {
        ...state,
        loading: false,
        postIds: Array.from(new Set(state.postIds.concat(normalized.result))),
        center: action.meta.center,
        sw,
        ne,
        posts: combinedPosts,
      };
    }
    case `${LOAD_BOUNDS}_PENDING`:
      return { ...state, zoom: action.meta.zoom, loading: true };
    case `${LOAD_BOUNDS}_FULFILLED`:
      normalized = normalize(action.payload.posts, [post]);

      console.log("old", state.zoom, "new", action.meta.zoom);
      if (state.zoom === action.meta.zoom) {
        return {
          ...state,
          loading: false,
          postIds: normalized.result,
          center: action.meta.center,
          zoom: action.meta.zoom,
          sw: action.meta.bounds.sw,
          ne: action.meta.bounds.ne,
          posts: { ...state.posts, ...normalized.entities.posts },
        };
      } else {
        return {
          ...state,
          postIds: normalized.result,
          posts: { ...state.posts, ...normalized.entities.posts },
        };
      }
    case `${LOAD_BOUNDS}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_OWN}_FULFILLED`:
      return {
        ...state,
        loading: false,
        ownPosts: action.payload.posts,
      };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_POST}_FULFILLED`: {
      const normalizedPost = normalize(action.payload.post, post);
      const normalizedComments = normalize(action.payload.comments, [comment]);

      return {
        ...state,
        posts: { ...state.posts, ...normalizedPost.entities.posts },
        commentsLoaded: state.commentsLoaded.concat(normalizedPost.result),
        comments: { ...state.comments, ...normalizedComments.entities.comments },
      };
    }
    case `${CREATE_POST}_PENDING`:
      return { ...state, creating: true };
    case `${CREATE_POST}_FULFILLED`:
      return {
        ...state,
        creating: false,
        postIds: [action.payload.id, ...state.postIds],
        posts: { ...state.posts, [action.payload.id]: action.payload },
      };
    case `${CREATE_POST}_REJECTED`:
      return { ...state, creating: false };
    case `${UPDATE_POST}_FULFILLED`:
      return {
        ...state,
        // creating: false,
        posts: { ...state.posts, [action.payload.id]: action.payload },
      };
    case `${DESTROY_POST}_FULFILLED`:
      return {
        ...state,
        postIds: state.postIds.filter(id => id !== action.payload.id),
      };
    case `${CREATE_COMMENT}_PENDING`:
      return { ...state, creating: true };
    case `${CREATE_COMMENT}_FULFILLED`:
      return {
        ...state,
        creating: false,
        comments: { ...state.comments, [action.payload.id]: action.payload },
      };
    case `${DESTROY_COMMENT}_FULFILLED`:
      let newComments = {...state.comments};
      delete newComments[action.payload.id];
      return {
        ...state,
        comments: newComments,
      };
    case `${CREATE_COMMENT}_REJECTED`:
      return { ...state, creating: false };
    case TOGGLE_POSTS_FILTER_TAG: {
      const updatedTags = updateFilterTags(state.filterTagIds, action.payload.tagId);
      return {
        ...state,
        filterTagIds: updatedTags.length === 0 ? action.meta.allTagIds : updatedTags,
      };
    }
    case SET_POSTS_FILTER_TAGS:
      return { ...state, filterTagIds: action.payload.map(tagId => parseInt(tagId, 10)) };
    case REMOVE_TAG_FILTERS:
      return { ...state, filterTagIds: action.payload.tagIds };
    case LOAD_TAGS:
      return {
        ...state,
        filterTagIds: state.filterTagIds || action.payload.map(tag => tag.id),
      };
    case SET_MAP_CENTER:
      return { ...state, center: action.payload };
    default:
      return state;
  }
}

const updateFilterTags = (currentTagIds, toggledId) => {
  currentTagIds = currentTagIds || [];
  if (currentTagIds.includes(toggledId)) {
    return currentTagIds.filter(id => id !== toggledId);
  } else {
    return currentTagIds.concat(toggledId);
  }
};

export const setPostsFilterTags = tagIds => {
  return {
    type: SET_POSTS_FILTER_TAGS,
    payload: tagIds,
  };
};

export const loadOwnPosts = () => {
  return {
    type: LOAD_OWN,
    payload: axios.get(`${HOST}/api/v1/posts/own`).then(response => response.data),
  };
};

const getOrigin = state => {
  const currentUser = state.users.current_user;
  let activeAddress;
  if (currentUser) {
    activeAddress = currentUser.active_address;
  } else {
    // TODO
    activeAddress = { latitude: 60.186191, longitude: 24.9659024 };
  }

  return {
    lat: parseFloat(activeAddress.latitude),
    lng: parseFloat(activeAddress.longitude),
  };
};

export const loadInitialBounds = mapSize => {
  return (dispatch, getState) => {
    const center = getOrigin(getState());

    return dispatch({
      type: LOAD_POST_BOUNDS,
      meta: { mapSize },
      payload: axios
        .get(`${HOST}/api/v1/post_bounds?lat=${center.lat}&lng=${center.lng}`)
        .then(response => response.data),
    });
  };
};

export const loadPostBounds = ({ mapSize, center, forceOrigin }) => {
  return (dispatch, getState) => {
    const { posts } = getState();
    const offset = posts.postIds.length;
    const tagIdsFragment = posts.filterTagIds.map(id => `tag_ids[]=${id}`).join("&");
    center = center || getOrigin(getState());

    let query = `lat=${center.lat}&lng=${center.lng}&offset=${offset}&${tagIdsFragment}`;

    if (forceOrigin) {
      query += "&force_origin=true";
    }

    return dispatch({
      type: LOAD_POST_BOUNDS,
      meta: { mapSize },
      payload: axios
        .get(`${HOST}/api/v1/post_bounds?${query}`)
        .then(response => response.data),
    });
  };
};

export const loadPosts = ({ center, offset = 0 }) => {
  return {
    type: LOAD,
    meta: {
      center,
    },
    payload: axios
      .get(`${HOST}/api/v1/posts?lat=${center.lat}&lng=${center.lng}&offset=${offset}`)
      .then(response => response.data),
  };
};

export const loadBounds = ({ center, bounds, zoom }) => {
  return (dispatch, getState) => {
    const origin = getOrigin(getState());

    return dispatch({
      type: LOAD_BOUNDS,
      meta: {
        center,
        bounds,
        zoom,
      },
      payload: axios
        .get(
          `${HOST}/api/v1/posts?lat=${origin.lat}&lng=${origin.lng}&sw=${bounds.sw.lat +
            "," +
            bounds.sw.lng}&ne=${bounds.ne.lat + "," + bounds.ne.lng}`
        )
        .then(response => response.data),
    });
  };
};

export const loadPost = postId => {
  return {
    type: LOAD_POST,
    payload: axios.get(`${HOST}/api/v1/posts/${postId}`).then(response => response.data),
  };
};

export function togglePostsFilterTag(tagId) {
  return (dispatch, getState) => {
    const { tags } = getState();

    dispatch({
      type: TOGGLE_POSTS_FILTER_TAG,
      meta: {
        allTagIds: tags.tags.map(tag => tag.id),
      },
      payload: { tagId },
    });
  };
}

export function removeTagFilters() {
  return (dispatch, getState) => {
    const { tags } = getState();

    dispatch({
      type: REMOVE_TAG_FILTERS,
      payload: { tagIds: tags.tags.map(tag => tag.id) },
    });
  };
}

export function createPost({
  tag_id,
  content,
  active_days,
  address_id,
  organization_id,
  image_attributes,
}) {
  return {
    type: CREATE_POST,
    payload: axios
      .post(`${HOST}/api/v1/posts`, {
        post: {
          tag_id,
          content,
          active_days,
          address_id,
          organization_id,
          image_attributes,
        },
      })
      .then(response => response.data),
  };
}

export function updatePost(postId, { tag_id, content, active_days, image_attributes }) {
  return {
    type: UPDATE_POST,
    payload: axios
      .patch(`${HOST}/api/v1/posts/${postId}`, {
        post: { tag_id, content, active_days, image_attributes },
      })
      .then(response => response.data),
  };
}

export function createComment(post_id, { content, image }) {
  return {
    type: CREATE_COMMENT,
    payload: axios
      .post(`${HOST}/api/v1/comments`, {
        comment: {
          post_id,
          content,
          image,
        },
      })
      .then(response => response.data),
  };
}

export function destroyPost(postId) {
  return {
    type: DESTROY_POST,
    payload: axios
      .delete(`${HOST}/api/v1/posts/${postId}`)
      .then(response => response.data),
  };
}

export function destroyComment(commentId) {
  return {
    type: DESTROY_COMMENT,
    payload: axios
      .delete(`${HOST}/api/v1/comments/${commentId}`)
      .then(response => response.data),
  };
}

export function setMapCenter(center) {
  return {
    type: SET_MAP_CENTER,
    payload: center,
  };
}

export function setMapBottom(point) {
  return (dispatch, getState) => {
    const {
      posts: { ne, sw },
    } = getState();

    let nudgeTowardsBottomOfView;
    if (sw && ne) {
      nudgeTowardsBottomOfView = (sw.lat - ne.lat) * 0.4;
    } else {
      nudgeTowardsBottomOfView = -0.007;
    }
    const center = { lat: point.lat - nudgeTowardsBottomOfView, lng: point.lng };

    dispatch({
      type: SET_MAP_CENTER,
      payload: center,
    });
  };
}

export function getPosts(state) {
  if (state.users.users.length === 0) return [];
  let posts = state.posts.postIds.map(id => state.posts.posts[id]);
  if (state.posts.filterTagIds && state.posts.filterTagIds.length > 0) {
    posts = posts.filter(post => state.posts.filterTagIds.includes(post.tag_id));
  }

  return posts.map(post => {
    let commentsCount;
    if (state.posts.commentsLoaded.includes(post.id)) {
      commentsCount = getCommentsForPost(state, post.id).length;
    } else {
      commentsCount = post.comments_count;
    }

    return {
      ...post,
      tag: state.tags.tags.find(tag => tag.id === post.tag_id),
      organization:
        post && post.organization_id && getOrganization(state, post.organization_id),
      user: state.users.users[post.author_id],
      comments_count: commentsCount,
    };
  });
}

export function getPost(state, postId) {
  let post = state.posts.posts[postId];
  let commentsCount;
  if (state.posts.commentsLoaded.includes(postId)) {
    commentsCount = getCommentsForPost(state, postId).length;
  } else {
    commentsCount = post && post.comments_count;
  }
  // loadCurrentUser().then(user => {
  //   console.log(user);
  // });

  return post
    ? {
        ...post,
        tag: state.tags.tags.find(tag => tag.id === post.tag_id),
        organization:
          post && post.organization_id && getOrganization(state, post.organization_id),
        user: state.users.users[post.author_id],
        comments_count: commentsCount,
        active_days: parseInt(
          (new Date(post.hide_at).getTime() - new Date(post.created_at).getTime()) /
            86400000
        ),
      }
    : null;
}

export function getCommentsForPost(state, postId) {
  return Object.values(state.posts.comments)
    .filter(comment => comment.post_id == postId)
    .map(comment => {
      return {
        ...comment,
        user: state.users.users[comment.author_id],
      };
    });
}

export function getBounds(state) {
  return { sw: state.posts.sw, ne: state.posts.ne };
}

export function getCenter(state) {
  return state.posts.center;
}

export function getZoom(state) {
  return state.posts.zoom;
}

export function getIsMinimumZoom(state) {
  if (!getZoom(state)) {
    return true;
  }
  return getZoom(state) <= MINIMUM_ZOOM;
}

export function getOwnPosts(state) {
  return state.posts.ownPosts;
}

export function isCommentDeletable(user, comment) {
  if (!user || !comment) {
    return false;
  }
  // TODO: Organization support?
  return user.id === comment.author_id;
}

export function isPostDeletable(user, post) {
  if (!user || !post) {
    return false;
  }
  return (
    user.id === post.user.id ||
    (post.organization_id && user.organization_id === post.organization_id)
  );
}
