import axios from "axios";
import { createSelector } from "reselect";

import { HOST } from "../globals";

import { LOAD as LOAD_CHAT } from "./chats";
import { LOAD as LOAD_POSTS } from "./posts";
import { LOAD_BOUNDS as LOAD_POSTS_BOUNDS } from "./posts";
import { LOAD_BOUNDS as LOAD_RESOURCES_BOUNDS } from "./resources";
import { LOAD_POST } from "./posts";
import { LOAD as LOAD_RESOURCES } from "./resources";
import { LOAD_RESOURCE } from "./resources";
const LOAD_CURRENT_USER = 'nappi-naapuri/users/LOAD_CURRENT_USER';
const LOAD = 'nappi-naapuri/users/LOAD';
const LOAD_USER = 'nappi-naapuri/users/LOAD_USER';

import { getUnreadThreadsCount } from "./chats";

function updateUsers(existingUsers, newUsers) {
  return newUsers.reduce(
    (acc, user) => ({
      ...acc,
      [user.id]: user,
    }),
    existingUsers
  );
}

export default function reducer(
  state = { current_user: null, userIds: [], users: {}, addresses: [], fetching: false },
  action
) {
  switch (action.type) {
    case `${LOAD_CURRENT_USER}_PENDING`:
      return { ...state, fetching: true };
    case `${LOAD_CURRENT_USER}_FULFILLED`:
      if (action.payload) {
        return {
          ...state,
          fetching: false,
          current_user: action.payload,
          users: updateUsers(state.users, [action.payload]),
        };
      } else {
        return {
          ...state,
          loading: false,
          current_user: null,
        };
      }
    case `${LOAD_CURRENT_USER}_REJECTED`:
      return { ...state, fetching: false };
    case `${LOAD_USER}_PENDING`:
      return { ...state, fetching: true };
    case `${LOAD_USER}_FULFILLED`:
      if (action.payload) {
        return {
          ...state,
          fetching: false,
          users: updateUsers(state.users, [action.payload]),
        };
      } else {
        return {
          ...state,
          loading: false
        };
      }
    case `${LOAD_USER}_REJECTED`:
      return { ...state, fetching: false };
    case `${LOAD}_PENDING`:
      return { ...state, fetching: true };
    case `${LOAD}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        userIds: action.payload.users.map(user => user.id),
        users: updateUsers(state.users, action.payload.users),
        addresses: action.payload.addresses,
      };
    case `${LOAD}_REJECTED`:
      return { ...state, fetching: false };
    case `${LOAD_CHAT}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(
          state.users,
          action.payload
            .map(message => message.sender)
            .concat(action.payload.map(message => message.receiver))
        ),
      };
    case `${LOAD_POSTS}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(state.users, action.payload.posts.map(post => post.author)),
      };
    case `${LOAD_RESOURCES}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(state.users, action.payload.resources.map(post => post.author)),
      };
    case `${LOAD_POSTS_BOUNDS}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(state.users, action.payload.posts.map(post => post.author)),
      };
    case `${LOAD_POST}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(
          state.users,
          action.payload.comments
            .map(post => post.author)
            .concat(action.payload.post.author)
        ),
      };
    case `${LOAD_RESOURCES_BOUNDS}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(state.users, action.payload.resources.map(resource => resource.author)),
      };
    case `${LOAD_RESOURCE}_FULFILLED`:
      return {
        ...state,
        users: updateUsers(
          state.users,
          action.payload.comments
            .map(resource => resource.author)
            .concat(action.payload.resource.author)
        ),
      };
    default:
      return { ...state };
  }
}

export const loadCurrentUser = () => {
  return {
    type: LOAD_CURRENT_USER,
    payload: axios.get(`${HOST}/api/v1/users/self`).then(response => response.data),
  };
};

export const loadUsers = ({ sw, ne }) => {
  return {
    type: LOAD,
    payload: axios
      .get(`${HOST}/api/v1/users?sw=${sw.lat + "," + sw.lng}&ne=${ne.lat + "," + ne.lng}`)
      .then(response => response.data),
  };
};

export const loadUser = (userId) => {
  return {
    type: LOAD_USER,
    payload: axios.get(`${HOST}/api/v1/users/${userId}`).then(response => response.data),
  };
};

export function getUser(state, userId) {
  return state.users.users[userId];
}

export function getUsers(state) {
  return state.userIds.map(id => state.users.users[id]);
}

const getAddresses = state => state.users.addresses;
const getUserMap = state => state.users.users;
const getUserIds = state => state.users.userIds;

export const getCurrentUserUnreadThreadsCount = state => {
  if (state.users.current_user) {
    const unreadThreadsCount = getUnreadThreadsCount(state, state.users.current_user.id);
    if (unreadThreadsCount !== null) {
      return unreadThreadsCount;
    } else {
      return state.users.current_user.unread_threads_count;
    }
  } else {
    return 0;
  }
};

export const getAddressesWithUsers = createSelector(
  getAddresses,
  getUserMap,
  getUserIds,
  (addresses, userMap, userIds) =>
    addresses
      .map(
        address =>
          userIds.includes(address.owner_id) && {
            ...address,
            user: userMap[address.owner_id],
          }
      )
      .filter(a => a)
);
