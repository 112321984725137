import axios from 'axios';
import { HOST } from '../globals';

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

class GoogleCalendarEventService {
  createGoogleCalendarEvent({ day, hour, email, name, phone }) {
    return axios.post('/api/v1/google_calendar_events', {
      day,
      hour,
      email,
      name,
      phone,
    });
  }
}

const googleCalendarEventService = new GoogleCalendarEventService();
export default googleCalendarEventService;
