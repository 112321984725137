export const statusBarTranslations = {
  strings: {
    preparingUpload: "Valmistellaan tallennusta…",
    // Shown in the status bar while files are being uploaded.
    uploading: "Tallennetaan kuvia",
    // Shown in the status bar once all files have been uploaded.
    complete: "Valmis!",
    // Shown in the status bar if an upload failed.
    uploadFailed: "Tallennus epäonnistui",
    // Shown next to `uploadFailed`.
    pleasePressRetry: "Paina Yritä uudelleen ja yritä tallennustaa",
    // Shown in the status bar while the upload is paused.
    paused: "Pysäytetty",
    error: "Virhe",
    // Used as the label for the button that retries an upload.
    retry: "Yritä uudelleen",
    // Used as the label for the button that cancels an upload.
    cancel: "Peruuta",
    // Used as the screen reader label for the button that retries an upload.
    retryUpload: "Yritä tallennusta uudelleen",
    // Used as the screen reader label for the button that pauses an upload.
    pauseUpload: "Laita tallennus tauolle",
    // Used as the screen reader label for the button that resumes a paused upload.
    resumeUpload: "Jatka tallennusta",
    // Used as the screen reader label for the button that cancels an upload.
    cancelUpload: "Peruuta tallennus",
    // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
    filesUploadedOfTotal: {
      0: "%{complete} tiedosto ladattu (yhteensä %{smart_count})",
      1: "%{complete} tiedostoa ladattu (yhteensä %{smart_count})",
    },
    // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
    dataUploadedOfTotal: "%{complete}/%{total}",
    // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
    xTimeLeft: "%{time} jäljellä",
    // Used as the label for the button that starts an upload.
    uploadXFiles: {
      0: "Tallenna %{smart_count} tiedosto",
      1: "Tallenna %{smart_count} tiedostoa",
    },
    // Used as the label for the button that starts an upload, if another upload has been started in the past
    // and new files were added later.
    uploadXNewFiles: {
      0: "Tallenna +%{smart_count} tiedosto",
      1: "Tallenna +%{smart_count} tiedostoa",
    },
  },
};
