import axios from 'axios';
import { HOST } from '../globals';

let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';

class InfoService {
  
  getInfoText() {
    return axios.get(`${HOST}/api/v1/sites/info`)
  }
}

const infoService = new InfoService();
export default infoService;
