import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import I18n from "i18n-js";

import { getCenter, loadBounds, getBounds, loadPostBounds } from "../ducks/posts";
import { loadResourceBounds } from "../ducks/resources";
import { loadTags } from "../ducks/tags";
import { loadResourceTags } from "../ducks/resourceTags";
import { loadOrganizations } from "../ducks/organizations";
import { loadCurrentUser } from "../ducks/users";

import Map from "./Map";
import ResourceMap from "./ResourceMap";
import List from "./List";
import Info from "./Info";
import PartnerModal from "./PartnerModal";
import EventModal from "./EventModal";
import ErrorBoundary from "./ErrorBoundary";

class GoogleAnalytics extends React.Component {
  componentDidMount() {
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    if (typeof window.gtag === "function") {
      const page = pathname + search;

      window.gtag("config", "UA-60914515-2", { page_path: page });
      window.gtag("event", "page_view");
    }
  }

  render() {
    return null;
  }
}

let token = document.getElementsByName("csrf-token")[0].getAttribute("content");
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";

class App extends React.Component {
  state = {
    listHidden: false,
  };

  componentDidMount() {
    this.props.loadTags();
    this.props.loadResourceTags();
    this.props.loadOrganizations();
    this.props.loadCurrentUser();
  }

  loadMorePosts = () => {
    this.props.loadPostBounds({
      center: this.props.center,
      mapSize: this.map.getWrappedInstance().mapSize(),
    });
  };

  loadMoreResources = () => {
    this.props.loadResourceBounds({
      center: this.props.center,
      mapSize: this.map.getWrappedInstance().mapSize(),
    });
  };

  hideList = () => {
    this.setState({ listHidden: true });
  };

  revealList = () => {
    this.setState({ listHidden: false });
  };

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <div className="flex even-children height-100vh" id="app-wrapper">
            <Route path="/" component={GoogleAnalytics} />
            <List
              hidden={this.state.listHidden}
              hideList={this.hideList}
              revealList={this.revealList}
              loadMorePosts={this.loadMorePosts}
              loadMoreResources={this.loadMoreResources}
            />

            <Switch>
              <Route
                path= "/resources"
                render={() => (
                  <div className="mapWrap" style={{ position: "relative" }}>
                    <ResourceMap
                      wrappedComponentRef={map => (this.map = map)}
                      revealList={this.revealList}
                      zoomOut={this.fetchMorePosts}
                    />
                  </div>
                )}
              />
              <Route
                path= "/feed/resources"
                render={() => (
                  <div className="mapWrap" style={{ position: "relative" }}>
                    <ResourceMap
                      wrappedComponentRef={map => (this.map = map)}
                      revealList={this.revealList}
                      zoomOut={this.fetchMorePosts}
                    />
                  </div>
                )}
              />
              <Route
                render={() => (
                  <div className="mapWrap" style={{ position: "relative" }}>
                    <Route path="/partners/:partnerId" component={PartnerModal} />
                    <Route path="/events/:eventId" component={EventModal} />
                    <Route exact path="/info" component={Info} />
                    <Map
                      wrappedComponentRef={map => (this.map = map)}
                      revealList={this.revealList}
                      zoomOut={this.fetchMorePosts}
                    />
                  </div>
                )}
              />
            </Switch>
          </div>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default connect(
  state => ({
    center: getCenter(state),
    bounds: getBounds(state),
  }),
  {
    loadCurrentUser,
    loadTags,
    loadResourceTags,
    loadBounds,
    loadPostBounds,
    loadResourceBounds,
    loadOrganizations,
  }
)(App);
