import axios from 'axios';
import { normalize, schema } from 'normalizr';

import { HOST } from '../globals';

export const LOAD = 'nappi-naapuri/organizations/LOAD';
export const LOAD_ORGANIZATION = 'nappi-naapuri/organizations/LOAD_ORGANIZATION';

const organization = new schema.Entity('organizations');

export default function reducer(
  state = { organizations: {}, organizationIds: [], loading: false },
  action
) {
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD}_FULFILLED`:
      var normalized = normalize(action.payload.organizations, [organization]);

      return {
        ...state,
        loading: false,
        organizationIds: normalized.result,
        organizations: { ...state.organizations, ...normalized.entities.organizations },
      };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_ORGANIZATION}_FULFILLED`:
      var normalized = normalize(action.payload.organization, organization);

      return {
        ...state,
        organizations: { ...state.organizations, ...normalized.entities.organizations },
      };
    default:
      return state;
  }
}

export const loadOrganizations = () => {
  return {
    type: LOAD,
    payload: axios.get(`${HOST}/api/v1/organizations`).then(response => response.data),
  };
};

export const loadOrganization = organizationId => {
  return {
    type: LOAD_ORGANIZATION,
    payload: axios
      .get(`${HOST}/api/v1/organizations/${organizationId}`)
      .then(response => response.data),
  };
};

export function getOrganizations(state) {
  return state.organizations.organizationIds.map(
    id => state.organizations.organizations[id]
  );
}

export function getOrganization(state, organizationId) {
  return state.organizations.organizations[organizationId];
}
