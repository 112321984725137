import React from "react";
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";
import cx from "classnames/bind";

import I18n from "../i18n.js";
import Feed from "./Feed";
import Resources from "./Resources";
import ResourceThread from "./ResourceThread";
import Thread from "./Thread";
import EditComment from "./EditComment";
import User from "./User";
import Messages from "./feed/Messages";

export default class List extends React.Component {
  render() {
    const classNames = cx("list listWrapper", {
      "list--hidden": this.props.hidden,
    });
    return (
      <React.Fragment>
        <div className={classNames} style={styles.container}>
          <div>
            <div className="mode-selector even-children width-100">
              <Link to={"/home"} className="padding-0-5 text-align-center activeMode">
                {I18n.t("js.list.list")}
              </Link>
              <button className="padding-0-5" onClick={this.props.hideList}>
                {I18n.t("js.list.map")}
              </button>
            </div>
          </div>
          <div className="listBgWrapper">
            <Route
              exact
              path="/:feed(home|info)"
              render={props => (
                <Feed {...props} loadMorePosts={this.props.loadMorePosts} />
              )}
            />
            <Route path="/messages" component={Messages} />
            <Route
              exact
              path="/resources"
              render={props => (
                <Resources {...props} loadMoreResources={this.props.loadMoreResources} />
              )}
            />
            <Route
              exact
              path="/resources/:resourceId"
              render={props => <ResourceThread {...props} onMount={this.props.revealList} />}
            />
            <Route
              exact
              path="/posts/:postId"
              render={props => <Thread {...props} onMount={this.props.revealList} />}
            />
            <Route
              path="/posts/:postId/edit"
              render={props => <EditComment {...props} />}
            />

            <Route
              path="/feed/posts/:postId"
              render={props => (
                <Feed {...props} loadMorePosts={this.props.loadMorePosts} />
              )}
            />
            <Route
              path="/feed/resources/:resourceId"
              render={props => (
                <Resources {...props} loadMoreResources={this.props.loadMoreResources} />
              )}
            />
            <Route
              path="/partners/:partnerId"
              render={props => (
                <Feed {...props} loadMorePosts={this.props.loadMorePosts} />
              )}
            />
            <Route
              path="/events/:eventId"
              render={props => (
                <Feed {...props} loadMorePosts={this.props.loadMorePosts} />
              )}
            />
            <Route
              path="/users/:userId"
              render={props => <User {...props} onMount={this.props.revealList} />}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  container: {
    maxWidth: "500px",
    minWidth: "500px",
    zIndex: 3,
  },
};
