import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import breaks from "remark-breaks";
import I18n from "../i18n.js";

import { getUser, loadUser } from "../ducks/users";
import { loadChat, getMessages, markThreadRead } from "../ducks/chats";
import { loadUserResources, getUserResources } from "../ducks/userResources";

import DefaultAvatar from "../images/default_avatar.png";
import WritePrivateMessage from "./feed/WritePrivateMessage";
import ImageViewer from "./feed/ImageViewer";
import Resource from './feed/Resource';
import Tabs from './tabs/Tabs';

import Moment from 'react-moment';

function PrivateMessages(props) {
  return (
    <div>
      {props.messages.map(message => {
        const dateToFormat = new Date(message.created_at);
        return (
          <div
            className="padding-0-5 border-1 border-bottom border-color-border"
            key={message.id}
          >
            <Moment className="post__date" format="DD.MM.YYYY HH:mm" date={dateToFormat}></Moment>
            <p>
              <strong>{message.sender.first_name}:</strong>
            </p>
            <ReactMarkdown
              source={message.content}
              plugins={[breaks]}
              className="message_formatted_content"
            />
            <ImageViewer image={message.image} />
          </div>
        );
      })}
    </div>
  );
}

function UserResources(props) {
  return (
    <div className="padding-0-5">
      {props.resources.map(resource => {
        return (
          <div
            key={resource.id}
          >
            <Resource
              key={resource.id}
              data={resource}
              linked displayCommentCount
            />
          </div>
        );
      })}
    </div>
  );
}


class User extends React.Component {
  state = { text: "" };

  componentDidMount() {
    this.props.loadUser(this.props.match.params.userId);
    this.props.loadChat(this.props.match.params.userId);
    this.props.onMount && this.props.onMount();
    this.props.markThreadRead(parseInt(this.props.match.params.userId, 10));
    this.props.loadUserResources(this.props.match.params.userId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.loadUser(this.props.match.params.userId);
      this.props.loadChat(this.props.match.params.userId);
      this.props.loadUserResources(this.props.match.params.userId);
      this.props.onMount && this.props.onMount();
    }

    // TODO: Center map here if the user address changes (posts.setMapBottom)
  }

  renderUser() {
    return (
      <div className="padding-0-5">
        <img
          src={this.props.user.avatar_url || DefaultAvatar}
          className="avatar avatar--huge"
        />
        <div className="text-align-center margin-y margin-1 bold size-1-25">
          {this.props.user.first_name}
        </div>
        <div className="text-align-center">
          {this.props.user.description}
        </div>
      </div>
    );
  }


  renderWriteComment(){
    return (
      <div className="comment-wrapper__write">
        {this.props.user ? (
          <WritePrivateMessage
            commentableId={this.props.user.id}
            placeholder={I18n.t("js.user.write_private_message")}
          />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div style={{overflow: "auto"}}>
        <div className="padding-0-5">
          <Link className="link" to={"/resources"} style={{ float: "left" }}>
            {I18n.t("js.global.back")}
          </Link>
        </div>
        {this.props.user ? this.renderUser() : null}
        <div className="padding-1 padding-top">
          <Tabs activeTab = {this.props.location.hash}>
            <div label={I18n.t("js.user.resources")}>
              {this.props.resources.length ?
                <div className="margin-bottom margin-3">
                  <UserResources resources={this.props.resources} />
                </div> :
                <div className="tab-list-placeholder">
                  {I18n.t("js.user.no_user_resources")}
                </div> }
            </div>
            <div label={I18n.t("js.user.private_messages")}>
              <div className="grow flex vertical tab-list-scroll margin-bottom margin-3">
                <div className="comment-wrapper grow margin-bottom margin-5">
                  {this.props.messages.length ?
                    <PrivateMessages messages={this.props.messages} /> :
                    <div className="tab-list-placeholder">
                      {I18n.t("js.user.write_private_message")}
                    </div>
                  }
                </div>
              </div>
            </div>
          </Tabs>
          {this.renderWriteComment()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.users.current_user,
    user: getUser(state, parseInt(props.match.params.userId, 10)),
    messages: getMessages(state, parseInt(props.match.params.userId, 10)),
    resources: getUserResources(state, parseInt(props.match.params.userId, 10)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { loadChat, loadUserResources, loadUser, markThreadRead }
  )(User)
);
