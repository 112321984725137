import React from "react";
import { Link } from "react-router-dom";

export default class UserCluster extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.id === this.props.id) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div className="user-cluster-marker">
        <div className="user-cluster-marker__inner">
          {this.props.numPoints}
        </div>
      </div>
    );
  }
}
