import React from "react";
import { Link } from "react-router-dom";

import infoService from "../services/InfoService";
import I18n from "../i18n.js";
import { facebookShareUrl } from "../helpers/facebook";

export default class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  componentDidMount() {
    infoService.getInfoText().then(response => {
      this.setState({ text: response.data });
    });
  }

  render() {
    const facebookAppId = document.body.getAttribute("data-facebook-app-id");

    return (
      <div className="text-content modal" style={styles.container}>
        <div className="subnav text-align-center margin-bottom margin-1">
          <a href="https://identity.coreorient.com/terms-and-conditions" className="link">
            {I18n.t("js.info.terms_of_use")}
          </a>
          <a href="https://identity.coreorient.com/privacy-policy" className="link">
            {I18n.t("js.info.privacy_policy")}
          </a>
        </div>

        <div className="text-align-right close-x">
          <Link className="size-2" to={"/home"}>
            ×
          </Link>
        </div>

        <h1 className="bold modal-heading">{I18n.t("js.global.nappi_naapuri")}</h1>

        <div className="margin-bottom margin-1 text-align-center">
          <a
            className="btn btn btn--primary width-50 fb-btn"
            href={facebookShareUrl(
              facebookAppId,
              "https://www.nappinaapuri.fi",
              "https://www.nappinaapuri.fi"
            )}
          >
            {I18n.t("js.info.share_in_facebook")}
          </a>
        </div>

        <div className="formatted_content">
          <blockquote>
            {I18n.t("js.info.nappi_naapuri_is")}
            <br />
            {I18n.t("js.info.neighbourhoods")}
            <br />
            {I18n.t("js.info.social_media")}.
          </blockquote>
          <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    background: "#fff",
    padding: "3rem 2rem 2rem",
    overflowY: "scroll",
    marginLeft: "1rem",
    maxWidth: "700px",
    zIndex: 1,
    position: "relative",
    height: "100%",
  },
};
