import React from "react";
import PropTypes from "prop-types";
import ReactTags from "react-tag-autocomplete";
import I18n from "../i18n.js";

class ResourceTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      suggestions: props.suggestions,
    };
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  render() {
    return (
      <React.Fragment>
        <ReactTags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete.bind(this)}
          handleAddition={this.handleAddition.bind(this)}
          allowNew="true"
          placeholder={I18n.t("js.resource_tags.add_tags")}
        />
        <input
          type="hidden"
          name="resource[tags]"
          value={JSON.stringify(this.state.tags, null, 2)}
        />
      </React.Fragment>
    );
  }
}

export default ResourceTags;
