import React from 'react';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import 'moment/locale/fi';

export default class DatePicker extends React.Component {
  state = {
    selectedDay: null,
  };

  handleDayClick = date => {
    this.setState({ selectedDay: date });
    this.props.onDateChange(date);
  };

  render() {
    return (
      <DayPicker
        selectedDays={this.state.selectedDay}
        onDayClick={this.handleDayClick}
        locale="fi"
        localeUtils={MomentLocaleUtils}
      />
    );
  }
}
