// Non-react pages

import 'babel-polyfill';

import '../honeybadger';

import Turbolinks from 'turbolinks';
import Rails from 'rails-ujs';

import autocompleteInit from '../sprinkles/autocomplete';
import floatingInput from '../sprinkles/floatingLabelInput';
import trixInit from '../sprinkles/trixInit';
import '../sprinkles/localeSelector';


Turbolinks.start();
Rails.start();

document.addEventListener('turbolinks:load', event => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', 'UA-60914515-2', {
      page_path: event.data.url,
      title: document.title,
    });
    window.gtag('event', 'page_view');
  }

  const signInButton = document.getElementById('signInButton');
  const signInForm = document.getElementById('signInForm');
  const signInChoose = document.getElementById('signInChoose');
  const newUserLink = document.getElementById('newUserLink');
  if (signInButton) {
    signInButton.onclick = function(event) {
      signInForm.style.display = 'block';
      signInChoose.style.display = 'none';
      newUserLink.style.display = 'none';
      return false;
    };
  }

  const userEmail = document.querySelector("[name='user[email]']");
  if (userEmail && userEmail.value) {
    signInForm && (signInForm.style.display = 'block');
    signInChoose && (signInChoose.style.display = 'none');
    newUserLink && (newUserLink.style.display = 'none');
  }

  autocompleteInit();
  floatingInput();
});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
