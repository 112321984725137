import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import classNames from "classnames/bind";
import DateFormat from "../DateFormat";
import Isvg from "react-inlinesvg";
import bubble from "../../images/bubble.svg";

class Resource extends React.Component {
  shouldComponentUpdate(nextProps, _nextState) {
    if (
      nextProps.resource.id === this.props.resource.id &&
      nextProps.active === this.props.active &&
      nextProps.resource.tag &&
      this.props.resource.tag &&
      nextProps.resource.tag.id === this.props.resource.tag.id
    ) {
      return false;
    } else {
      return true;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resource && nextProps.active && !this.props.active) {
      this.props.centerToResource(nextProps.resource);
    }
  }

  renderImage() {
    if (this.props.resource.marker_avatar_url) {
      return (
        <div
          style={{ backgroundImage: `url(${this.props.resource.marker_avatar_url})` }}
          className="avatar"
        />
      );
    } else {
      return null;
    }
  }

  renderBubble() {
    if (this.props.currentUser) {
      return (
        <Link
          className={this.getStackLevelClass()}
          to={`/feed/resources/${this.props.resource.id}`}
          style={{ zIndex: 2 }}
        >
          <Isvg
            src={bubble}
            className="bubbleMarker"
            cacheGetRequests={true}
            style={this.getFill()}
          />
          <div className={this.getMarkerClassNames()}>
            <div
              className="post-marker__inner post-marker__closed"
              style={this.getColor()}
            >
              <div className="post-marker__content">{this.renderImage()}</div>
            </div>
          </div>
        </Link>
      );
    } else {
      return (
        <div style={{ zIndex: 2 }} className={this.getStackLevelClass()}>
          <Isvg
            src={bubble}
            className="bubbleMarker"
            cacheGetRequests={true}
            style={this.getFill()}
          />
          <div className={this.getMarkerClassNames()}>
            <div
              className="post-marker__inner post-marker__closed"
              style={this.getColor()}
            >
              <div className="post-marker__content">{this.renderImage()}</div>
            </div>
          </div>
        </div>
      );
    }
  }

  getAuthorName() {
    return this.props.resource.author_name;
  }

  getAuthorUrl() {
    return `/users/${this.props.resource.user.id}`;
  }

  getOrganizationUrl() {
    return `/events/${this.props.resource.organization_tag_slug}`;
  }

  getMarkerClassNames() {
    return classNames("post-marker", {
      "post-marker--active": this.props.active,
      "post-marker--closed": !this.props.active,
      "post-marker--open": this.props.active,
    });
  }

  getStackLevelClass() {
    return classNames(`post-marker-stack-${this.props.resource.stack_level}`);
  }

  isSubject() {
    return (
      this.props.resource && this.props.resource.tag && this.props.resource.tag.status === "subject"
    );
  }

  isTheme() {
    return (
      this.props.resource && this.props.resource.tag && this.props.resource.tag.status === "theme"
    );
  }

  // TODO fix these styles
  getColor() {
    return {
      background: this.isSubject() ? "#becd3c" : "#becd3c",
      color: this.isSubject() ? "#becd3c" : "#becd3c",
    };
  }

  // TODO fix these styles
  getFill() {
    return {
      fill: this.isSubject() ? "#becd3c" : "#becd3c",
    };
  }

  getStyle() {
    if (this.props.resource.organization_tag_slug) {
      return { backgroundImage: `url(${this.props.resource.marker_avatar_url})` };
    } else {
      return { backgroundImage: `url(${this.props.resource.avatar_url})` };
    }
  }
  renderAuthor() {
    if (this.props.resource.organization_tag_slug) {
      return (
        <div className="marker-meta__name">
          <Link to={this.getOrganizationUrl()}>{this.props.resource.organization_name}</Link>
        </div>
      );
    } else {
      return (
        <div className="marker-meta__name">
          <Link to={this.getAuthorUrl()}>{this.getAuthorName()}</Link>
        </div>
      );
    }
  }
  renderThemeBubble() {
    if (this.isTheme()) {
      return (
        <Link to={`/events/${this.props.resource.tag.slug}`}>
          <div className="marker-meta__organization align-center flex">
            {this.renderImage()}
            {this.props.resource.tag.name}
          </div>
        </Link>
      );
    }
  }
  render() {
    if (this.props.active) {
      return (
        <div className={this.getStackLevelClass()}>
          <div
            className={this.getMarkerClassNames()}
            style={{ position: "relative", zIndex: 1 }}
          >
            <Isvg
              src={bubble}
              className="bubbleMarker"
              cacheGetRequests={true}
              style={this.getFill()}
            />
            <div className="post-marker__inner" style={this.getColor()}>
              {this.props.currentUser ? (
                <Link to={"/resources/"}>
                  <div className="closeCross color-primary">×</div>
                </Link>
              ) : null}
              <div className="post-marker__content">
                <div className="post-marker__top">
                  <Link
                    to={
                      this.props.resource.organization_tag_slug
                        ? this.getOrganizationUrl()
                        : this.getAuthorUrl()
                    }
                  >
                    <div style={this.getStyle()} className="avatar avatar--large" />
                  </Link>
                  <div className="marker-meta">
                    {this.renderAuthor()}
                    {this.renderThemeBubble()}
                    <div className="marker-meta__date">
                      <DateFormat date={this.props.resource.created_at} />
                    </div>
                  </div>
                </div>
                <Link to={`/resources/${this.props.resource.id}`}>
                  <div className="marker-content text-align-center">
                    {this.props.resource.content.substring(0, 140) + "…"}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.renderBubble();
    }
  }
}

export default withRouter(Resource);
