import React from 'react';
import { Link } from 'react-router-dom';
import Edit from './feed/Edit';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPost, loadPost, getCommentsForPost, isPostDeletable } from '../ducks/posts';
import Post from './feed/Post';
import I18n from "../i18n.js";

class EditComment extends React.Component {
  componentDidMount() {
    this.props.onMount && this.props.onMount();
    this.props.loadPost(this.props.match.params.postId);
  }

  render() {
    return (
      <div className="grow flex vertical">
        <div className="padding-0-75">
          <Link className="link" to={`/home`}>
            {I18n.t("js.global.back")}
          </Link>
        </div>
        {this.props.post ? <Edit data={this.props.post} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const post = getPost(state, parseInt(props.match.params.postId));
  return {
    currentUser: state.users.current_user,
    post,
    comments: getCommentsForPost(state, parseInt(props.match.params.postId)),
    deletable: isPostDeletable(state.users.current_user, post),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { loadPost }
  )(EditComment)
);
