import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import map from "lodash/map";
import find from "lodash/find";

import ReactTags from 'react-tag-autocomplete'

import I18n from "../i18n.js";
import {
  toggleMenuOpen,
  toggleFiltersOpen,
  toggleEventsOpen,
} from "../ducks/navigation";
import { getCurrentUserUnreadThreadsCount } from "../ducks/users";
import { HOST } from "../globals";
import caretDown from "../images/icon-chevron-d.svg";
import caretUp from "../images/icon-chevron-u.svg";

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class ResourceFilter extends React.Component {
  constructor(props) {
    super(props);
    let ids = [];
    if (props.filterTagIds && props.filterTagIds.length > 0) {
      ids = map(props.filterTagIds, item => {
        const found = find(props.tags, { id: item });
        return { id: item, name: found && found.name };
      });
    }
    this.state = {
      tags: ids,
      range: [30],
      notification: true,
    };
  }

  handleDelete (i) {
    const removedTag = this.state.tags[i]
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
    this.props.toggleResourceTag(removedTag.id);
  }

  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
    this.props.toggleResourceTag(tag.id);
  }

  renderTagSelector () {
    return (
      <ReactTags
        tags={this.state.tags}
        suggestions={this.props.tags}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        placeholder= {I18n.t("js.resource_tags.filter_with_tags")}
      />
    )
  }


  // TODO extract rangeslider to own component
  // http://react-component.github.io/slider/
  // http://react-component.github.io/slider/examples/handle.html
  handleChange = range => {
    this.setState({ range });
  };

  renderRangeSelector () {
    return(
      <div className="range-flex range-slider">
        <div className="range-slider">
          {I18n.t("js.resource_filter.range")}
        </div>
        <div className="wrapperStyle">
          <Slider
            min={0}
            max={150}
            defaultValue={this.state.range}
            handle={handle}
            onChange={this.handleChange}
            tipFormatter={value => `${value}km`} />
        </div>
      </div>
    );
  }

  // NOTE this is SUODATA in feed
  render() {
    return (
      <div>
      <div className="accordion__item_nav nav__border-bottom">
        <div className="accordion__content padding-0-25 margin-0-25 margin-y">
          <a href={`${HOST}/own_resources`} className="own_resource__link">
            + {I18n.t("js.nav.add_own_resource")}
          </a>
        </div>
      </div>
      <div className="accordion__item_nav nav__border-bottom">
          <div className="accordion__content padding-0-25 margin-0-25 margin-y">
            <div className="tag-filtering">
              {this.renderTagSelector()}
            </div>
          </div>
      </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    menuOpen: state.navigation.menuOpen,
    filtersOpen: state.navigation.filtersOpen,
    eventsOpen: state.navigation.eventsOpen,
    unreadMessagesCount: getCurrentUserUnreadThreadsCount(state),
  }),
  {
    toggleMenuOpen,
    toggleFiltersOpen,
    toggleEventsOpen,
  }
)(ResourceFilter);
