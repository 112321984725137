import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Redirect } from "react-router-dom";

import I18n from "../../i18n.js";
import PrivateMessages from "./PrivateMessages";
import PublicMessages from "./PublicMessages";

import { getCurrentUserUnreadThreadsCount } from "../../ducks/users";

class Messages extends React.Component {
  render() {
    return (
      <div className="grow flex vertical">
        <div className="padding-0-75">
          <Link className="link" to={`/home`}>
            {I18n.t("js.global.back")}
          </Link>
        </div>
        <div className="flex even-children align-center tab-nav margin-1 margin-y">
          <NavLink
            to="/messages/public"
            activeClassName="active"
            className="text-align-center tab-nav-item padding-1"
          >
            {I18n.t("js.feed.messages.public_conversations")}
          </NavLink>
          <NavLink
            to="/messages/private"
            activeClassName="active"
            className="text-align-center tab-nav-item padding-1"
          >
            <div className="flex">
              {I18n.t("js.feed.messages.private_conversations")}
              {this.props.unreadMessagesCount ? (
                <div className="notification-bubble">
                  <span>{this.props.unreadMessagesCount}</span>
                </div>
              ) : null}
            </div>
          </NavLink>
        </div>

        <Redirect exact from="/messages" to="/messages/public" />
        <Route path="/messages/public" component={PublicMessages} />
        <Route path="/messages/private" component={PrivateMessages} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    unreadMessagesCount: getCurrentUserUnreadThreadsCount(state),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Messages)
);
