import React from "react";
import { withRouter } from "react-router";
import GoogleMap from "google-map-react";
import { connect } from "react-redux";

import Resource from "./map/Resource";
import Marker from "./map/Marker";
import I18n from "../i18n.js";

import { DEBUG } from "../globals";
import { loadCurrentUser } from "../ducks/users";
import {
  loadBounds,
  loadResourceBounds,
  loadResource,
  getResources,
  getBounds,
  getCenter,
  setMapBottom,
  getZoom,
  loadInitialBounds,
} from "../ducks/resources";
import { getTag } from "../ducks/resourceTags";

import mapOptions from "../helpers/mapOptions";

const updateTreshold = 0.001;

class ResourceMap extends React.Component {
  state = {};

  componentDidMount() {
    this.props.loadCurrentUser().then(() => {
      const resourcePath = this.props.location.pathname.match(/\/resources\/(\d+)/);
      if (resourcePath) {
        this.props.loadResource(resourcePath[1]).then(response => {
          const center = {
            lat: response.value.resource.address.latitude + 0.007,
            lng: response.value.resource.address.longitude,
          };
          // this.props.setMapSize(this.mapSize());
          // this.props.setMapCenter(center);
          this.props.loadResourceBounds({
            mapSize: this.mapSize(),
            center: center,
            forceOrigin: true,
          });
        });
      } else {
        // this.props.setMapSize(this.mapSize());
        this.props.loadInitialBounds(this.mapSize());
      }
    });
  }

  shouldUpdateNodes(lat, lng, zoom) {
    if (this.state.zoom !== zoom) {
      return true;
    }
    const center = this.state.center;
    return (
      center &&
      (lat > center.lat + updateTreshold ||
      lat < center.lat - updateTreshold ||
      lng > center.lng + updateTreshold ||
      lng < center.lng - updateTreshold)
    );
  }

  mapChanged({ center, bounds, zoom }) {
    if (this.shouldUpdateNodes(center.lat, center.lng, zoom)) {
      this.props.loadBounds({ center, bounds, zoom });
      this.setState({
        center,
        zoom,
      });
    }
  }

  handleCenterToResource = resource => {
    this.props.setMapBottom({ lat: resource.address.latitude, lng: resource.address.longitude });
  };

  renderResources() {
    return this.props.resources.map(resource => {
      return (
        <Resource
          key={`resource-${resource.id}`}
          lat={resource.address.latitude}
          lng={resource.address.longitude}
          currentUser={this.props.currentUser}
          resource={resource}
          active={
            this.props.location.pathname === `/resources/${resource.id}`||
            this.props.location.pathname === `/feed/resources/${resource.id}`
          }
          centerToResource={this.handleCenterToResource}
        />
      );
    });
  }

  mapSize() {
    if (this.map) {
      const size = {
        width: this.map.offsetWidth,
        height: this.map.offsetHeight,
      };

      return size;
    }
  }

  renderDebugMarkers() {
    return [
      <Marker key="ne" lat={this.props.bounds.ne.lat} lng={this.props.bounds.ne.lng} />,
      <Marker key="sw" lat={this.props.bounds.sw.lat} lng={this.props.bounds.sw.lng} />,
      <Marker key="center" lat={this.props.center.lat} lng={this.props.center.lng} />,
    ];
  }

  mapOptions() {
    if (this.props.allowLowMinZoom) {
      return { ...mapOptions, minZoom: 6 };
    } else {
      return mapOptions;
    }
  }

  render() {
    return (
      <div className="map" style={{ height: "100%" }} ref={map => (this.map = map)}>
        <div className="mode-selector even-children width-100">
          <button className="padding-0-5" onClick={this.props.revealList}>
            {I18n.t("js.map.list")}
          </button>
          <button className="padding-0-5 activeMode">{I18n.t("js.map.map")}</button>
        </div>

        {this.props.center && this.props.zoom ? (
          <GoogleMap
            onChange={this.mapChanged.bind(this)}
            style={{}}
            bootstrapURLKeys={{ key: "AIzaSyBMqVGEkx4XDT-uu6tYbyK6XbqK2DfVY2M" }}
            options={this.mapOptions()}
            center={this.props.center}
            zoom={this.props.zoom}
          >
            {this.renderResources()}
            {DEBUG && this.renderDebugMarkers()}
          </GoogleMap>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const bounds = getBounds(state);
  return {
    currentUser: state.users.current_user,
    resources: getResources(state),
    bounds: bounds,
    center: getCenter(state),
    zoom: getZoom(state),
    currentAddressId:
      state.users.current_user && state.users.current_user.active_address.id,
    allowLowMinZoom:
      state.resources.filterTagIds.length === 1 &&
      getTag(state, state.resources.filterTagIds[0]),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadCurrentUser,
      loadInitialBounds,
      loadResourceBounds,
      loadBounds,
      loadResource,
      setMapBottom,
    },
    null,
    { withRef: true }
  )(ResourceMap)
);
