import React from "react";

export default function ImageViewer(props) {
  if (!props.image) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
      <a href={props.image.large_image_url} target="_blank" rel="noopener noreferrer">
        <div style={{ width: "70%", padding: 5 }}>
          <img alt={props.image.large_image_url} src={props.image.large_image_url} />
        </div>
      </a>
    </div>
  );
}
