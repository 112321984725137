import classNames from "classnames/bind";
import React from "react";
import Isvg from "react-inlinesvg";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import I18n from "../../i18n.js";

import { getOrganization } from "../../ducks/organizations.js";
import { destroyPost } from "../../ducks/posts.js";
import IconEdit from "../../images/edit.svg";
import IconComment from "../../images/icon-comment.svg";
import IconTrash from "../../images/trash-2.svg";
import ImageViewer from "./ImageViewer";

function TagName(props) {
  if (props.tag) {
    if (props.tag.status === "theme") {
      return <Link to={`/events/${props.tag.slug}`}>{props.tag.name}</Link>;
    } else {
      return props.tag.name;
    }
  } else {
    return null;
  }
}

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveringAvatar: false,
    };
  }

  destroyPost = () => {
    if (window.confirm(I18n.t("js.feed.post.deletion_are_you_sure"))) {
      this.props.destroyPost(this.props.data.id).then(() => {
        this.props.history.push("/home");
      });
    }
  };

  renderDeleteButton() {
    if (this.props.deletable) {
      return (
        <div className="margin-0-5 margin-left flex align-center">
          <button onClick={this.destroyPost}>
            <Isvg className="icon-post-option" src={IconTrash} />
          </button>
        </div>
      );
    }
  }

  renderEditButton() {
    if (this.props.deletable) {
      return (
        <Link to={`/posts/${this.props.data.id}/edit`}>
          <Isvg className="icon-post-option" src={IconEdit} />
        </Link>
      );
    }
  }

  renderCommentCount() {
    let count = this.props.data.comments_count;
    if (this.props.data.comments_count === 0 || !this.props.data.comments_count) {
      count = "0";
    }

    const commentLabelClassNames = classNames("icon-label", {
      "icon-label--empty":
        !this.props.data.comments_count || this.props.data.comments_count === 0,
    });
    if (this.props.displayCommentCount) {
      if (this.props.linked && this.props.data && this.props.data.id) {
        return (
          <Link
            className="margin-0-5 margin-left flex"
            to={`/posts/${this.props.data.id}`}
          >
            <div className={commentLabelClassNames}>
              <Isvg
                src={IconComment}
                cacheGetRequests={true}
                className="icon-label__icon"
              />
              <div className="icon-label__label">{count}</div>
            </div>
          </Link>
        );
      } else {
        return (
          <div className="margin-0-5 margin-left flex">
            <div className={commentLabelClassNames}>
              <Isvg
                src={IconComment}
                cacheGetRequests={true}
                className="icon-label__icon"
              />
              <div className="icon-label__label">{count}</div>
            </div>
          </div>
        );
      }
    }
  }

  renderContent(limit) {
    const truncatedContent =
      limit && this.props.data.content.length > 140
        ? this.props.data.content.substring(0, limit) + "…"
        : this.props.data.content;

    const content = (
      <div className="post__content padding-left padding-2">
        <div className="post__summary">
          <ReactMarkdown
            className="message_formatted_content"
            source={truncatedContent}
            plugins={[breaks]}
          />
        </div>
        <ImageViewer image={this.props.data.image} />
      </div>
    );
    if (this.props.linked) {
      return <Link to={`/posts/${this.props.data.id}`}>{content}</Link>;
    } else {
      return content;
    }
  }

  getAuthorName() {
    if (this.props.data.organization_name) {
      return this.props.data.organization_name;
    } else {
      return this.props.data.author_name;
    }
  }

  getAvatarUrl() {
    if (this.linkAvatarToTag()) {
      return `/events/${this.props.data.tag.slug}`;
    } else {
      return this.getAuthorUrl();
    }
  }

  getAuthorUrl() {
    if (this.props.data.organization_tag_slug) {
      return `/events/${this.props.data.organization_tag_slug}`;
    } else {
      return `/users/${this.props.data.user.id}`;
    }
  }

  linkAvatarToTag() {
    if (!this.props.data.tag) {
      return false;
    }
    return this.props.data.tag.status === "theme";
  }

  renderPost(limit) {
    const postUserClassNames = classNames("post__user", {
      "post__user--hover": this.state.hoveringAvatar,
    });
    let avatarUrl;
    if (this.props.data.organization_id && this.props.data.marker_avatar_url) {
      avatarUrl = this.props.data.marker_avatar_url;
    } else if (
      this.props.data.tag &&
      this.props.data.tag.status === "theme" &&
      this.props.data.tag.logo_url
    ) {
      avatarUrl = this.props.data.tag.logo_url;
    } else {
      avatarUrl = this.props.data.user.avatar_url;
    }
    const style = {
      backgroundImage: `url(${avatarUrl})`,
      backgroundColor: this.props.data.tag ? this.props.data.tag.color : null,
    };

    return (
      <React.Fragment>
        <div className="post-header">
          {this.props.data.user && (
            <React.Fragment>
              <div className="post-header__left">
                <Link
                  to={this.getAvatarUrl()}
                  onMouseOver={() => {
                    if (!this.linkAvatarToTag()) {
                      this.setState({ hoveringAvatar: true });
                    }
                  }}
                  onMouseOut={() => {
                    if (!this.linkAvatarToTag()) {
                      this.setState({ hoveringAvatar: false });
                    }
                  }}
                  className="post__avatar"
                >
                  <div className="avatar" style={style} />
                </Link>
              </div>
              <div className="post-header__right">
                <Link to={this.getAuthorUrl()} className={postUserClassNames}>
                  {this.getAuthorName()}
                </Link>
                <div className="post__date">
                  <Moment format={"D.M.YY"}>{this.props.data.created_at}</Moment>
                  {this.props.data.hide_at && (
                    <span>
                      {" "}
                      – {I18n.t("js.feed.post.expires_at_begin")}{" "}
                      <Moment format={"D.M.YY"}>{this.props.data.hide_at}</Moment>{" "}
                      {I18n.t("js.feed.post.expires_at_end")}
                    </span>
                  )}
                  <span>
                    {" "}
                    – <TagName tag={this.props.data.tag} />
                  </span>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="flex align-center">
            {this.renderEditButton()}
            {this.renderDeleteButton()}
            {this.renderCommentCount()}
          </div>
        </div>
        {this.renderContent(limit)}
      </React.Fragment>
    );
  }

  render() {
    const style = { background: this.props.data.tag ? this.props.data.tag.color : null };

    if (this.props.linked) {
      return (
        <div className="post post--linked" style={style}>
          {this.renderPost(140)}
        </div>
      );
    } else {
      return (
        <div className="post" style={style}>
          {this.renderPost()}
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state, props) => {
      return {
        organization: props.data.organization_id
          ? getOrganization(state, props.data.organization_id)
          : null,
      };
    },
    { destroyPost }
  )(Post)
);
