import * as $ from 'jquery';

export default function autocompleteInit() {
  if (!document.getElementById('google-maps-autocomplete')) return;
  else if (window.google && window.google.places) initMap();
  else
    $.ajax(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyATguiW7NNvdpYqCatadKgPHDbDlxO54tg&libraries=places&types;=geocode&callback=initMap',
      {
        crossDomain: true,
        dataType: 'script',
      },
    );
}

var autocomplete;
var addressForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
};
var autoCompleteOptions = {
  componentRestrictions: {country: "fi"}
}

function initMap() {
  autocomplete = new google.maps.places.Autocomplete(document.getElementById('google-maps-autocomplete'), autoCompleteOptions);
  autocomplete.addListener('place_changed', placeChanged);
}
window.initMap = initMap;

function placeChanged() {
  var place = autocomplete.getPlace();
  if (!place.geometry) {
    $("#submit-address").attr("disabled", true)
    return;
  } else {
    $("#submit-address").attr("disabled", false)
  }

  for (var i = 0; i < place.address_components.length; i++) {
    var addressType = place.address_components[i].types[0];
    if (addressForm[addressType]) {
      var val = place.address_components[i][addressForm[addressType]];
      $(`#${addressType}`).val(val);
    }
  }

  $('#latitude').val(place.geometry.location.lat());
  $('#longitude').val(place.geometry.location.lng());
}
