import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import I18n from "../i18n.js";
import {
  toggleMenuOpen,
  toggleFiltersOpen,
  toggleEventsOpen,
  closeFilters,
  togglePartnersOpen,
} from "../ducks/navigation";
import { getCurrentUserUnreadThreadsCount } from "../ducks/users";
import { HOST } from "../globals";
import caretDown from "../images/icon-chevron-d.svg";
import caretUp from "../images/icon-chevron-u.svg";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: true,
    };
  }

  componentDidMount() {
    this.props.closeFilters();
  }

  renderPartners() {
    return this.renderTags(
      true,
      this.props.tags.filter(tag => tag.status === "partner"),
      tag => (
        <React.Fragment>
          &nbsp;
          <Link
            className="link"
            to={{ pathname: "/events/" + tag.slug, state: { openedFrom: "feed" } }}
          >
            {I18n.t("js.global.read_more")}
          </Link>
        </React.Fragment>
      )
    );
  }

  renderEvents() {
    return this.renderTags(
      true,
      this.props.tags.filter(tag => tag.status === "theme"),
      tag => (
        <React.Fragment>
          &nbsp;
          <Link
            className="link"
            to={{ pathname: "/events/" + tag.slug, state: { openedFrom: "feed" } }}
          >
            {I18n.t("js.global.read_more")}
          </Link>
        </React.Fragment>
      )
    );
  }

  renderTags(showLabel, tags, renderLink) {
    const filterTagIds = this.props.filterTagIds;

    return tags.map(tag => {
      const active = filterTagIds.length === 0 || filterTagIds.includes(tag.id);

      return (
        <li key={tag.id}>
          <button
            onClick={event => {
              this.props.toggleTag(tag.id);
              event.stopPropagation();
            }}
          >
            <span
              className="accordion__filter-ball"
              style={
                active
                  ? { backgroundColor: tag.color }
                  : { borderColor: tag.color, borderWidth: "2px", borderStyle: "solid" }
              }
            />
            {showLabel && tag.name}
          </button>
          {renderLink && renderLink(tag)}
        </li>
      );
    });
  }

  renderSubjects(showLabel = true) {
    return this.renderTags(
      showLabel,
      this.props.tags.filter(tag => tag.status === "subject")
    );
  }

  renderEventsNav() {
    return (
      <div className="padding-0-50">
        <button className="margin-0-5 margin-y" onClick={this.props.toggleEventsOpen}>
          {!this.props.eventsOpen && (
            <span className="uppercase weight-900 museo-sans">
              {I18n.t("js.nav.themes")}{" "}
              <img className="accordion__caret--small" src={caretDown} />
            </span>
          )}
          {this.props.eventsOpen && (
            <span className="uppercase weight-900 museo-sans">
              {I18n.t("js.nav.themes")}{" "}
              <img className="accordion__caret--small" src={caretUp} />
            </span>
          )}
        </button>
        {this.props.eventsOpen && <ul>{this.renderEvents()}</ul>}
      </div>
    );
  }

  renderPartnersNav() {
    return (
      <div className="padding-0-50">
        <button className="margin-0-5 margin-y" onClick={this.props.togglePartnersOpen}>
          {!this.props.partnersOpen && (
            <span className="uppercase weight-900 museo-sans">
              {I18n.t("js.nav.partners")}{" "}
              <img className="accordion__caret--small" src={caretDown} />
            </span>
          )}
          {this.props.partnersOpen && (
            <span className="uppercase weight-900 museo-sans">
              {I18n.t("js.nav.partners")}{" "}
              <img className="accordion__caret--small" src={caretUp} />
            </span>
          )}
        </button>
        {this.props.partnersOpen && <ul>{this.renderPartners()}</ul>}
      </div>
    );
  }

  renderFilterNav() {
    return (
      <div className="accordion__item_nav nav__border-bottom">
        <div
          className="accordion__title flex justify padding-0-25"
          onClick={this.props.toggleFiltersOpen}
        >
          <div className="width-50 uppercase museo-sans weight-900">
            {I18n.t("js.nav.filter")}
          </div>
          <div className="">
            {!this.props.filtersOpen && (
              <img className="accordion__caret" src={caretDown} />
            )}
            {this.props.filtersOpen && <img className="accordion__caret" src={caretUp} />}
          </div>
          <div className="accordion__filters width-50 text-align-right">
            <ul>{this.renderSubjects(false)}</ul>
          </div>
        </div>

        {this.props.filtersOpen && (
          <div className="accordion__content padding-0-25 margin-0-25 margin-bottom">
            <div className="flex even-children">
              <ul>
                <li>
                  <button className="showAllButton" onClick={this.props.removeTagFilters}>
                    {I18n.t("js.nav.show_all")}
                  </button>
                </li>
                {this.renderSubjects()}
              </ul>
            </div>
            {this.renderEventsNav()}
            {this.renderPartnersNav()}
          </div>
        )}
      </div>
    );
  }

  // TODO move constructors to correct location
  render() {
    const activePostsNav = (match, location) => {
      if (
        location.pathname.match("/home") ||
        location.pathname.match("/feed/posts") ||
        location.pathname.match("/info")
      ) {
        return true;
      } else {
        return false;
      }
    };

    const activeResourcesNav = (match, location) => {
      if (
        location.pathname.match("/resources") ||
        location.pathname.match("/feed/resources")
      ) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <div>
        <div className="accordion padding-1 padding-top">
          <div className="accordion__item_nav">
            {this.props.menuOpen && (
              <div className="accordion__content">
                <ul className="navLinks">
                  <li>
                    <Link className="link" to={"/info"}>
                      {I18n.t("js.feed.info")}
                    </Link>
                  </li>
                  <li>
                    <a href={`${HOST}/profile/edit`} className="link">
                      {I18n.t("js.nav.profile_editing")}
                    </a>
                  </li>
                  {this.props.currentUser.organization_id && (
                    <li>
                      <a href={`${HOST}/organization/edit`} className="link">
                        {I18n.t("js.nav.partner_editing")}
                      </a>
                    </li>
                  )}
                  <li className="flex">
                    <Link className="link" to={"/messages"}>
                      {I18n.t("js.nav.own_conversations")}
                    </Link>
                    {this.props.unreadMessagesCount ? (
                      <div className="notification-bubble">
                        <span>{this.props.unreadMessagesCount}</span>
                      </div>
                    ) : null}
                  </li>
                  {/* <li>
                   <a href={`${HOST}/addresses`} className="link">
                     Omat osoitteet
                   </a>
                  </li>*/}
                  <li>
                    <a href={`${HOST}/own_resources`} className="link">
                      {I18n.t("js.nav.own_resources")}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${HOST}/users/sign_out`}
                      data-method="delete"
                      className="link"
                    >
                      {I18n.t("js.nav.sign_out")}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="accordion__item_nav">
            <div className="flex even-children align-center margin-y">
              <NavLink
                to="/home"
                isActive={activePostsNav}
                activeClassName="active"
                className="tab-feed-item"
              >
                {I18n.t("js.feed.nav.posts")}
              </NavLink>
              <NavLink
                to="/resources"
                isActive={activeResourcesNav}
                activeClassName="active"
                className="tab-feed-item"
              >
                {I18n.t("js.feed.nav.resources")}
              </NavLink>
            </div>
          </div>
          {location.pathname.match(/home/) ? this.renderFilterNav() : null}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    menuOpen: state.navigation.menuOpen,
    filtersOpen: state.navigation.filtersOpen,
    eventsOpen: state.navigation.eventsOpen,
    partnersOpen: state.navigation.partnersOpen,
    unreadMessagesCount: getCurrentUserUnreadThreadsCount(state),
  }),
  {
    toggleMenuOpen,
    toggleFiltersOpen,
    toggleEventsOpen,
    togglePartnersOpen,
    closeFilters,
  }
)(Nav);
