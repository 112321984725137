import React from "react";
import Textarea from "react-textarea-autosize";
import classNames from "classnames/bind";

import I18n from "../i18n.js";
import googleCalendarEventService from "../services/GoogleCalendarEventService";

import DatePicker from "./DatePicker";

let initialState = {
  name: "",
  phone: "",
  email: "",
  hour: 9,
  date: null,
  message: "",
};

const TIMES = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

export default class CalendarEventForm extends React.Component {
  state = { ...initialState, loading: false };

  clear() {
    this.setState(initialState);
  }

  refreshIframe() {
    const iframe = document.getElementById("naapuripyora-google-calendar");
    iframe && (iframe.src = iframe.src);
  }

  submit = event => {
    event.preventDefault();
    this.setState({ loading: true });
    googleCalendarEventService
      .createGoogleCalendarEvent({
        email: this.state.email,
        name: this.state.name,
        phone: this.state.phone,
        day: this.state.date,
        hour: this.state.hour,
      })
      .then(({ data }) => {
        this.setState({ loading: false });
        if (data && data.message) {
          this.refreshIframe();
          this.setState({
            message: data.message,
          });
        }
      })
      .catch(({ response }) => {
        this.setState({ loading: false });
        if (response && response.data) {
          this.setState({
            message: response.data.message,
          });
        }
      });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDateChange = date => {
    this.setState({ date: date });
  };

  renderTimes() {
    return TIMES.map(hour => {
      return (
        <option key={hour} value={hour}>
          {hour}:00 – {hour + 1}:00
        </option>
      );
    });
  }

  renderMessage() {
    if (this.state.message && this.state.message.length > 0) {
      return (
        <div className="message bold padding-1 margin-0-5 margin-bottom">
          {this.state.message}
        </div>
      );
    }
  }

  submitDisabled() {
    return (
      this.state.loading ||
      this.state.email.length === 0 ||
      this.state.name.length === 0 ||
      this.state.phone.length === 0 ||
      !this.state.date
    );
  }

  render() {
    return (
      <form onSubmit={this.submit} className="margin-0-5 margin-bottom">
        <div className="row">
          <div className="col-xs-12 margin-0-5 margin-bottom">
            <label>{I18n.t("js.calendar_event_form.your_name")}</label>
            <input
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-m-6 margin-0-5 margin-bottom">
            <label>{I18n.t("js.calendar_event_form.your_email")}</label>
            <input
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col-xs-12 col-m-6 margin-0-5 margin-bottom">
            <label>{I18n.t("js.calendar_event_form.your_phonenumber")}</label>
            <input
              name="phone"
              type="tel"
              value={this.state.phone}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-m-12 col-xl-6 margin-0-5 margin-bottom">
            <label>{I18n.t("js.calendar_event_form.day")}</label>
            <DatePicker onDateChange={this.handleDateChange} />
          </div>
          <div className="col-xs-12 col-m-6 margin-0-5 margin-bottom">
            <label>{I18n.t("js.calendar_event_form.time")}</label>
            <select name="hour" onChange={this.handleInputChange} value={this.state.hour}>
              {this.renderTimes()}
            </select>
          </div>
        </div>

        {this.renderMessage()}
        <button
          className="generic__button"
          type="submit"
          disabled={this.submitDisabled()}
        >
          {this.state.loading
            ? I18n.t("js.global.saving")
            : I18n.t("js.calendar_event_form.reserve_time")}
        </button>
      </form>
    );
  }
}
