import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

function getSignedRequest(url, file) {
  url += `?filename=${file.name}&type=${file.type}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  }).then(response => {
    if (response.status === 200) {
      return response.json().then(json => {
        return { fields: json.fields, url: json.url, method: json.method };
      });
    } else {
      alert("Tiedoston lataaminen ei onnistunut.");
      return null;
    }
  });
}

export function createUppy(presignUrl) {
  return new Uppy({
    id: "uppy1",
    autoProceed: true,
    debug: false,
    restrictions: { maxFileSize: 50 * 1024 * 1024, maxNumberOfFiles: 1 },
  }).use(AwsS3, {
    getUploadParameters(file) {
      return getSignedRequest(presignUrl, file);
    },
  });
}

export function fileToJson(file) {
  if (file) {
    return {
      id: file.meta.key.match(/cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        filename: file.name,
        size: file.size,
        mime_type: file.meta["Content-Type"],
      },
    };
  }
}
