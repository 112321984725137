import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import I18n from "../i18n.js";

import Write from "./feed/Write";
import Post from "./feed/Post";
import Nav from "./Nav";
import Comment from "./feed/Comment";
import SignUpInstruction from "./SignUpInstruction";
import Logo from "../images/logo.png";
import { getLoading } from "../ducks";
import { toggleMenuOpen } from "../ducks/navigation";
import {
  getPosts,
  togglePostsFilterTag,
  setPostsFilterTags,
  removeTagFilters,
  setMapCenter,
  getIsMinimumZoom,
  isPostDeletable,
} from "../ducks/posts";
import { loadNewestBulletin, getBulletin } from "../ducks/bulletin";
import { getTags, getTagBySlug } from "../ducks/tags";
import homeIcon from "../images/home-icon-primary.png";

class Feed extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    if (this.highlightedItem) {
      this.highlightedItem.scrollIntoView({ behavior: "smooth" });
    }
    this.props.loadNewestBulletin();
  }

  componentWillReceiveProps(nextProps) {

    let sortedByDatePosts = nextProps.posts.sort(function(a,b){
      return new Date(b.created_at) - new Date(a.created_at);
    });

    //if map found new items, destructure them after the old items
    if(nextProps.posts.length > this.state.posts.length){

      const oldPostsIds = this.state.posts.map(item => item.id)
      const newItemsFoundAfterMovingMap = nextProps.posts.filter(nextPropItem => !oldPostsIds.includes(nextPropItem.id))

      this.setState({posts: [...this.state.posts, ...newItemsFoundAfterMovingMap]})
    }
    //else map found less items than previous zoom/position, order found items by date
    else {
      if(nextProps.posts){
        this.setState({posts: sortedByDatePosts })
      }
    }

    if (nextProps.eventId && this.props.eventId != nextProps.eventId) {
      this.props.setPostsFilterTags([nextProps.eventId]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.highlightPostId !== this.props.highlightPostId &&
      this.highlightedItem
    ) {
      this.highlightedItem.scrollIntoView({ behavior: "smooth" });
    }
  }

  handleScroll = event => {
    // const bottom =
    //   event.target.scrollHeight - event.target.scrollTop - 50 <=
    //   event.target.clientHeight;
    // if (bottom) {
    //   console.log('Load more');
    //
    //   if (!this.props.loadingPosts) {
    //     this.props.loadMorePosts();
    //   }
    // }
  };

  renderBulletin() {
    if (this.props.bulletin.bulletin) {
      return (
        <div className="feed-posts__item">
          <div className="post post--linked" style={{ background: "rgb(245, 150, 30)" }}>
            <h1 className="bold">
              <a href={this.props.bulletin.bulletin.link_url}>
                {this.props.bulletin.bulletin.title}
              </a>
            </h1>
            <p>{this.props.bulletin.bulletin.body}</p>
            <p>
              {this.props.bulletin.bulletin.link_url ? (
                <a
                  className="link"
                  href={this.props.bulletin.bulletin.link_url}
                  target="_blank"
                >
                  {I18n.t("js.global.read_more")}
                </a>
              ) : null}
            </p>
          </div>
        </div>
      );
    }
  }

  renderPosts() {
    const highlightPostId = this.props.highlightPostId;
    return this.state.posts.map(post => {
      const highlightClass = highlightPostId === post.id ? "highlight" : "";
      return (
        <div
          key={post.id}
          ref={node =>
            highlightPostId === post.id ? (this.highlightedItem = node) : null
          }
          className={highlightClass + " feed-posts__item"}
        >
          <Post
            deletable={isPostDeletable(this.props.currentUser, post)}
            data={post}
            linked={true}
            displayCommentCount={true}
          />
        </div>
      );
    });
  }

  toggleTag = tagId => {
    this.props.togglePostsFilterTag(tagId);
  };

  renderEvent() {
    return (
      <div className="flex vertical align-center text-align-center event">
        <div
          className="avatar eventAvatar"
          style={{ backgroundImage: `url(${this.props.event.logo_url})` }}
        />
        <h1 className="size-1-25 museo-sans bold color-primary margin-top margin-0-25">
          {this.props.event.name}
        </h1>
      </div>
    );
  }

  centerToHome = () => {
    this.props.setMapCenter({
      lat: this.props.currentUser.active_address.latitude,
      lng: this.props.currentUser.active_address.longitude,
    });
  };

  renderLoadMoreButton = () => {
    if (!this.props.currentUser) return;
    if (this.props.loadingPosts) {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          <span>{I18n.t("js.global.loading")}</span>
        </div>
      );
    }

    if (this.props.hasMorePostsToLoad) {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          <button className="generic__button" onClick={this.props.loadMorePosts}>
            {I18n.t("js.feed.load_more_messages")}
          </button>
        </div>
      );
    } else {
      return (
        <div className="text-align-center margin-1 margin-bottom">
          {I18n.t("js.feed.move_map_to_find_messages")}
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div>
          <div className="text-align-center padding-y flex align-center justify relative">
            <div className="headerLeft">
              {this.props.currentUser && (
                <div
                  className="homeIcon inline cursor-pointer"
                  onClick={this.centerToHome}
                >
                  <img src={homeIcon} />
                  <span>{I18n.t("js.feed.home")}</span>
                </div>
              )}
            </div>
            <div className="headerCenter">
              <img className="inline logo" src={Logo} />
            </div>
            <div className="headerRight">
              {!this.props.currentUser && (
                <div className="infoLink">
                  <Link className="link" to={"/info"}>
                    {I18n.t("js.feed.info")}
                  </Link>
                </div>
              )}
              {this.props.currentUser && (
                <button
                  className={
                    this.props.menuOpen ? "hamburger hamburgerOpen" : "hamburger"
                  }
                  onClick={this.props.toggleMenuOpen}
                >
                  <div />
                  <div />
                  <div />
                </button>
              )}
            </div>
          </div>
          {!this.props.currentUser && this.props.event && this.renderEvent()}
          {this.props.currentUser && (
            <React.Fragment>
              <Nav
                filterTagIds={this.props.filterTagIds}
                tags={this.props.tags}
                organizations={this.props.organizations}
                toggleTag={this.toggleTag}
                removeTagFilters={this.props.removeTagFilters}
                currentUser={this.props.currentUser}
              />
              <div className="margin-bottom margin-1 write-wrapper">
                <Write />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="news-post">{this.renderBulletin()}</div>
        <div className="feed-posts" onScroll={this.handleScroll}>
          {this.renderPosts()}
          {this.renderLoadMoreButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const tag = state.tags.activeTag;
  return {
    posts: getPosts(state),
    bulletin: getBulletin(state),
    tags: getTags(state),
    filterTagIds: state.posts.filterTagIds,
    highlightPostId: parseInt(props.match.params.postId),
    event: tag,
    eventId: tag && tag.id,
    currentUser: state.users.current_user,
    loadingPosts: getLoading(state, "posts"),
    hasMorePostsToLoad: !getIsMinimumZoom(state),
    menuOpen: state.navigation.menuOpen,
  };
};

export default connect(
  mapStateToProps,
  {
    toggleMenuOpen,
    togglePostsFilterTag,
    setPostsFilterTags,
    removeTagFilters,
    setMapCenter,
    loadNewestBulletin,
  }
)(Feed);
