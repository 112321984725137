import { combineReducers } from 'redux';
import users from './users';
import posts from './posts';
import resources from './resources';
import bulletin from './bulletin';
import tags from './tags';
import resourceTags from './resourceTags';
import chats from './chats';
import navigation from './navigation';
import organizations from './organizations';
import userResources from './userResources';

export default combineReducers({
  users,
  posts,
  tags,
  resourceTags,
  bulletin,
  chats,
  userResources,
  navigation,
  organizations,
  resources,
});

export function getLoading(state, reducerName) {
  return state[reducerName] && state[reducerName].loading;
}
