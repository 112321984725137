import React from 'react';
import { Link } from 'react-router-dom';

import HomeIcon from '../../images/home-icon.png';

export default class User extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user.id === this.props.user.id) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    if (this.props.isHome) {
      return (
        <Link to={`/users/${this.props.user.id}`} className="user-marker">
          <img src={HomeIcon} />
        </Link>
      );
    } else {
      return (
        <Link to={`/users/${this.props.user.id}`} className="user-marker">
          <div className="user-marker__inner" />
        </Link>
      );
    }
  }
}
