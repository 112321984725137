import React from "react";

export default class Tag extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "20px",
          height: "20px",
          background: "tomato",
          borderRadius: "100%",
          border: "1px solid #fff",
          cursor: "pointer",
        }}
      />
    );
  }
}
