import axios from 'axios';
import { HOST } from '../globals';

const LOAD = 'nappi-naapuri/tags/LOAD';
export const LOAD_FULFILLED = `${LOAD}_FULFILLED`;
const LOAD_TAG = 'nappi-naapuri/tags/LOAD_TAG';

export default function reducer(state = { tags: [], activeTag: null, loading: false }, action) {
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case LOAD_FULFILLED:
      return { ...state, loading: false, tags: action.payload };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };
    case `${LOAD_TAG}_FULFILLED`:
      return {
        ...state,
        loading: false,
        activeTag: action.meta.openedFrom == "feed" ? null : action.payload,
        tags: state.tags
          .filter(tag => tag.id !== action.payload.id)
          .concat(action.payload),
      };
    default:
      return { ...state };
  }
}

export const loadTags = () => {
  return {
    type: LOAD,
    payload: axios.get(`${HOST}/api/v1/tags`).then(response => response.data),
  };
};

export const loadTag = ({ id, openedFrom }) => {
  return {
    type: LOAD_TAG,
    meta: {
      openedFrom
    },
    payload: axios.get(`${HOST}/api/v1/tags/${id}`).then(response => response.data),
  };
};

export function getTags(state) {
  return state.tags.tags;
}

export function getTag(state, id) {
  return state.tags.tags.find(tag => tag.id === id);
}

export function getTagBySlug(state, slug) {
  return state.tags.tags.find(tag => tag.slug === slug);
}
