const TOGGLE_MENU_OPEN = "nappi-naapuri/navigation/TOGGLE_MENU_OPEN";
const TOGGLE_FILTERS_OPEN = "nappi-naapuri/navigation/TOGGLE_FILTERS_OPEN";
const CLOSE_FILTERS = "nappi-naapuri/navigation/CLOSE_FILTERS";
const TOGGLE_EVENTS_OPEN = "nappi-naapuri/navigation/TOGGLE_EVENTS_OPEN";
const TOGGLE_PARTNERS_OPEN = "nappi-naapuri/navigation/TOGGLE_PARTNERS_OPEN";
const WRITE_SWITCH_OPEN = "nappi-naapuri/navigation/WRITE_SWITCH_OPEN";
const WRITE_SWITCH_CLOSE = "nappi-naapuri/navigation/WRITE_SWITCH_CLOSE";

export default function reducer(
  state = {
    menuOpen: false,
    filtersOpen: false,
    eventsOpen: false,
    partnersOpen: false,
    writeOpen: false,
  },
  action
) {
  let filtersNewState = state.filtersOpen;
  let menuNewState = state.menuOpen;
  let writeNewState = state.writeOpen;

  switch (action.type) {
    case WRITE_SWITCH_OPEN:
      console.log("open");
      return { ...state, writeOpen: true, filtersOpen: false, menuOpen: false };
    case WRITE_SWITCH_CLOSE:
      console.log("close");
      return { ...state, writeOpen: false, filtersOpen: false, menuOpen: false };
    case TOGGLE_MENU_OPEN:
      if (state.menuOpen === false) {
        filtersNewState = false;
        writeNewState = false;
      }
      return {
        ...state,
        menuOpen: !state.menuOpen,
        filtersOpen: filtersNewState,
        writeOpen: writeNewState,
      };
    case TOGGLE_FILTERS_OPEN:
      if (state.filtersOpen === false) {
        menuNewState = false;
        writeNewState = false;
      }
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
        menuOpen: menuNewState,
        writeOpen: writeNewState,
      };
    case CLOSE_FILTERS:
      return {
        ...state,
        filtersOpen: false,
      };
    case TOGGLE_EVENTS_OPEN:
      return { ...state, eventsOpen: !state.eventsOpen };
    case TOGGLE_PARTNERS_OPEN:
      return { ...state, partnersOpen: !state.partnersOpen };
    default:
      return state;
  }
}

export const toggleMenuOpen = () => {
  return {
    type: TOGGLE_MENU_OPEN,
  };
};

export const toggleFiltersOpen = () => {
  return {
    type: TOGGLE_FILTERS_OPEN,
  };
};

export const closeFilters = () => {
  return {
    type: CLOSE_FILTERS,
  };
};

export const toggleEventsOpen = () => {
  return {
    type: TOGGLE_EVENTS_OPEN,
  };
};

export const togglePartnersOpen = () => {
  return {
    type: TOGGLE_PARTNERS_OPEN,
  };
};

export const writeSwitchOpen = () => {
  return {
    type: WRITE_SWITCH_OPEN,
  };
};

export const writeSwitchClose = () => {
  return {
    type: WRITE_SWITCH_CLOSE,
  };
};
