import React from "react";

import Honeybadger from "../honeybadger";
import I18n from "../i18n.js";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    Honeybadger.notify(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>{I18n.t("js.error_boundary.something_went_wrong")}</h1>;
    }
    return this.props.children;
  }
}
