import React from "react";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
import classNames from "classnames/bind";

import StatusBar from "@uppy/react/lib/StatusBar";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import { withRouter } from "react-router-dom";

import { createPost } from "../../ducks/posts";
import { getOrganizations } from "../../ducks/organizations";
import { writeSwitchOpen } from "../../ducks/navigation";
import { writeSwitchClose } from "../../ducks/navigation";
import { createUppy, fileToJson } from "../../helpers/uploads";
import { statusBarTranslations } from "../../helpers/translations";
import I18n from "../../i18n.js";

import PostFileUpload from "./PostFileUpload";
import Image from "./Image";

const COVID_DEFAULT_ID = 34;

let initialState = {
  text: "",
  tagId: null,
  addressId: null,
  activeDays: 7,
  image: null,
  uploading: false,
};

class Write extends React.Component {
  state = initialState;

  constructor(props) {
    super(props);

    this.uppy = createUppy("/post_images/presign");
    this.uppy.on("upload", _data => {
      this.setState({
        uploading: true,
      });
    });
    this.uppy.on("complete", _data => {
      this.setState({
        uploading: false,
      });
    });
  }

  componentDidMount() {
    this.setDefaultValues(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setDefaultValues(newProps);
  }

  setDefaultValues(newProps) {
    if (!initialState.tagId) {
      if (
        COVID_DEFAULT_ID &&
        newProps.tags &&
        newProps.tags.find(tag => tag.id === COVID_DEFAULT_ID)
      ) {
        initialState.tagId = COVID_DEFAULT_ID;
      } else {
        initialState.tagId = newProps.tags && newProps.tags[0] && newProps.tags[0].id;
      }
      this.setState({ tagId: initialState.tagId });
    }
    if (!initialState.addressId) {
      initialState.addressId =
        newProps.currentUser &&
        newProps.currentUser.addresses &&
        newProps.currentUser.addresses[0] &&
        newProps.currentUser.addresses[0].id;
      this.setState({ addressId: initialState.addressId });
    }
  }

  clear = () => {
    this.setState(initialState);
    this.props.writeSwitchClose();
  };

  submit(event) {
    event.preventDefault();
    this.props
      .createPost({
        tag_id: this.state.tagId,
        content: this.state.text,
        active_days: this.state.activeDays,
        address_id: this.state.addressId,
        organization_id: this.state.organizationId,
        image_attributes: this.state.image && {
          image: JSON.stringify(fileToJson(this.state.image.file)),
        },
      })
      .then(({ value }) => {
        this.clear();

        this.props.history.push(`/posts/${value.id}`);
      });
  }

  textChanged(event) {
    this.setState({ text: event.target.value });
  }

  tagChanged = event => {
    this.setState({ tagId: event.target.value });
  };

  addressChanged = event => {
    this.setState({ addressId: event.target.value });
  };

  selectedOrganization() {
    return (
      this.state.organizationId &&
      this.props.organizations.find(
        organization => organization.id === this.state.organizationId
      )
    );
  }

  organizationChanged = event => {
    const organizationId = !isNaN(event.target.value)
      ? parseInt(event.target.value, 10)
      : undefined;

    let addresses, addressId, tagId;
    if (organizationId) {
      addresses = this.props.organizations.find(
        organization => organization.id === organizationId
      ).addresses;
      addressId = addresses[0] && addresses[0].id;
      tagId = this.props.organizations.find(
        organization => organization.id === organizationId
      ).tag_id;
    } else {
      addresses = this.props.currentUser.addresses;
      addressId = addresses[0] && addresses[0].id;
      tagId = this.props.tags && this.props.tags[0] && this.props.tags[0].id;
    }
    this.setState({
      organizationId,
      addressId,
      tagId,
    });
  };

  textFocused() {
    this.props.writeSwitchOpen();
  }

  activeDaysChanged(event) {
    this.setState({ activeDays: event.target.value });
  }

  renderTags() {
    var newTags = this.props.tags
      .filter(tag => {
        if (this.selectedOrganization()) {
          return this.selectedOrganization().tag_id === tag.id;
        } else {
          return !tag.organization_id;
        }
      })
      .map(tag => {
        return (
          <option key={tag.id} value={tag.id}>
            {tag.name}
          </option>
        );
      });
    return newTags;
  }

  renderAddressSelect() {
    if (
      (!this.selectedOrganization() && this.props.currentUser.addresses.length > 1) ||
      (this.selectedOrganization() && this.selectedOrganization().addresses.length > 1)
    ) {
      let options;

      if (this.selectedOrganization()) {
        options = this.selectedOrganization().addresses.map(address => {
          return (
            <option key={address.id} value={address.id}>
              {address.street_name}
            </option>
          );
        });
      } else {
        options = this.props.currentUser.addresses.map(address => {
          return (
            <option key={address.id} value={address.id}>
              {address.street_name || address.title}
            </option>
          );
        });
      }

      return (
        <div className="write-options">
          <div className="write-options__column">
            <div className="floating-select floating-select--no-borders">
              <label>{I18n.t("js.feed.write.choose_address")}</label>
              <select onChange={this.addressChanged} value={this.state.addressId}>
                {options}
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  renderOrganizationSelect() {
    if (this.props.organizations.length > 0) {
      const options = [
        { id: null, name: this.props.currentUser.first_name },
        ...this.props.organizations,
      ].map(organization => {
        return (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        );
      });

      return (
        <div className="write-options">
          <div className="write-options__column">
            <div className="floating-select floating-select--no-borders">
              <label>{I18n.t("js.feed.write.publish_as_user")}</label>
              <select
                onChange={this.organizationChanged}
                value={this.state.organizationId}
              >
                {options}
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  handleImagesUploaded = uploads => {
    const images = uploads.map(file => ({
      preview: URL.createObjectURL(file.data),
      file: file,
      id: file.id,
    }));

    this.setState(state => ({ image: images[0] }));
  };

  handleImageRemove = index => {
    if (this.state.image && this.state.image.id) {
      this.uppy.removeFile(this.state.image.id);
    }
    this.setState({ image: null });
  };

  renderOptions() {
    return (
      <React.Fragment>
        {this.renderOrganizationSelect()}
        {this.renderAddressSelect()}
        <div className="write-options">
          <div className="write-options__column">
            <div className="floating-select floating-select--no-borders">
              <label>{I18n.t("js.feed.write.choose_tag")}</label>
              <select onChange={this.tagChanged} value={this.state.tagId}>
                {this.renderTags()}
              </select>
            </div>
          </div>
          <div className="write-options__column">
            <div className="floating-select floating-select--no-borders">
              <label>{I18n.t("js.feed.write.choose_validity_period")}</label>
              <select
                onChange={this.activeDaysChanged.bind(this)}
                value={this.state.activeDays}
              >
                <option value="1">1 {I18n.t("js.global.day")}</option>
                <option value="7">1 {I18n.t("js.global.week")}</option>
                <option value="30">1 {I18n.t("js.global.month")}</option>
                <option value="365">1 {I18n.t("js.global.year")}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="write__footer">
          <button onClick={this.clear} className="write__cancel">
            {I18n.t("js.global.cancel")}
          </button>
          <button
            type="submit"
            className="write__submit"
            disabled={this.state.uploading || this.state.text.length === 0}
          >
            {I18n.t("js.global.send")}
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (!this.props.currentUser) return null;

    const writeClassNames = classNames("write", {
      "write--active": this.props.writeOpen,
      "write--has-text": !this.state.uploading && this.state.text.length > 0,
    });

    return (
      <form onSubmit={this.submit.bind(this)} className={writeClassNames}>
        <div className="write-textarea">
          <div
            style={{
              backgroundImage: `url(${
                this.selectedOrganization() && this.selectedOrganization().logo_url
                  ? this.selectedOrganization().logo_url
                  : this.props.currentUser.avatar_url
              }`,
            }}
            className="write-textarea__avatar avatar"
          />
          <Textarea
            className="write-textarea__input"
            type="text"
            minRows={1}
            placeholder={I18n.t("js.feed.write.write_new_message")}
            value={this.state.text}
            onChange={this.textChanged.bind(this)}
            onFocus={this.textFocused.bind(this)}
          />
          <PostFileUpload
            uppy={this.uppy}
            onImagesUploaded={this.handleImagesUploaded}
            visible={this.props.writeOpen}
          />
        </div>
        <StatusBar
          uppy={this.uppy}
          hideUploadButton
          hideAfterFinish
          showProgressDetails
          locale={statusBarTranslations}
        />
        <Image image={this.state.image} onImageRemove={this.handleImageRemove} />
        {this.props.writeOpen ? this.renderOptions() : null}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.users.current_user,
    tags: state.tags.tags,
    organizations: getOrganizations(state),
    writeOpen: state.navigation.writeOpen,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { createPost, writeSwitchOpen, writeSwitchClose }
  )(Write)
);
