import $ from "jquery";

$(document).on("turbolinks:load", event => {
  $(".langSelector select").change(function(event) {
    var confirmation_token = new URL(location.href).searchParams.get("confirmation_token")
    if(confirmation_token) {
      window.location = location.protocol + '//' + location.host + location.pathname + "?locale=" + this.value + "&confirmation_token=" + confirmation_token;
    } else {
      window.location = location.protocol + '//' + location.host + location.pathname + "?locale=" + this.value;
    }
  })
});
