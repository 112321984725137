import axios from 'axios';
import { HOST } from '../globals';

const LOAD = 'nappi-naapuri/bulletin/LOAD';

export default function reducer(state = { bulletin: [] }, action) {
 switch (action.type) {
   case `${LOAD}_PENDING`:
     return { ...state, loading: true };
   case `${LOAD}_FULFILLED`:
     return { ...state, loading: false, bulletin: action.payload };
   case `${LOAD}_REJECTED`:
     return { ...state, loading: false };
   default:
     return { ...state };
 }
}

export const loadNewestBulletin = () => {
  return {
    type: LOAD,
    payload: axios
      .get(`${HOST}/api/v1/bulletins/newest`)
      .then(response => response.data),
  };
};

export function getBulletin(state) {
 return state.bulletin;
}
