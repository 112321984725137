import Isvg from "react-inlinesvg";
import React from "react";
import FileInput from "@uppy/file-input";
import IconCamera from "../../images/camera.svg";

import "./PostFileUpload.css";

export default class PostFileUpload extends React.Component {
  state = {
    uploading: false,
  };

  constructor(props) {
    super(props);

    props.uppy.on("complete", result => {
      this.props.onImagesUploaded(result.successful);
    });
  }

  componentDidMount() {
    this.props.uppy.use(FileInput, {
      target: this.input,
      pretty: true,
    });
  }

  render() {
    return (
      <div className="uppy-container" style={{display: this.props.visible ? "block" : "none"}}>
        <div ref={el => (this.input = el)}>
          <Isvg className="icon-post-option" src={IconCamera} />
        </div>
      </div>
    );
  }
}
