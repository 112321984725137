import React from 'react';
import { Link } from 'react-router-dom';

export default class PartnerModal extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <div className="text-align-right close-x">
          <Link className="size-2" to={'/home'}>×</Link>
        </div>

        <h1 className="bold modal-heading">KUMPPANI!</h1>
      </div>
    );
  }
}

const styles = {
  container: {
    background: "#fff",
    padding: "3rem 2rem 2rem",
    overflowY: 'scroll',
    marginLeft: "1rem",
    maxWidth: '700px',
    zIndex: 1,
    position: "relative",
    height: "100%",
  }
};
