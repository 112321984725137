import React from "react";

export default function Image(props) {
  if (!props.image) {
    return null;
  }
  if (props.image._destroy) {
    return null;
  }

  if (props.image.file) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", borderTop: "none" }}>
        <div
          className="image_remove_wrapper"
          key={props.image.file.meta.key}
          style={{ width: "33%", padding: 5, cursor: "pointer" }}
          onClick={() => props.onImageRemove()}
        >
          <img alt={props.image.file.name} src={props.image.preview} />
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", borderTop: "none" }}>
        <div
          className="image_remove_wrapper"
          key={props.image.small_image_url}
          style={{ width: "33%", padding: 5, cursor: "pointer" }}
          onClick={() => props.onImageRemove()}
        >
          <img alt={props.image.small_image_url} src={props.image.small_image_url} />
        </div>
      </div>
    );
  }
}
