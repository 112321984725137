import axios from 'axios';
import { HOST } from '../globals';

import findIndex from "lodash.findindex";

export const LOAD = 'nappi-naapuri/user_resources/LOAD';

export default function reducer(state = { resources: [], loading: false, creating: false }, action) {
  switch (action.type) {
    case `${LOAD}_PENDING`:
      return { ...state, loading: true };
    case `${LOAD}_FULFILLED`:
      return { ...state, loading: false, resources: action.payload };
    case `${LOAD}_REJECTED`:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const loadUserResources = userId => {
  return {
    type: LOAD,
    payload: axios.get(`${HOST}/api/v1/user_resources/${userId}`).then(response => response.data),
  };
};

export function getUserResources(state, userId) {
  if (!state.users.current_user) return [];
  return state.userResources.resources.map(resource => {
    if (
      [userId].includes(resource.author_id)
    )
      return {
        ...resource,
        user: state.users.users[resource.author_id],
      };
  }).filter(resource => resource);
}
